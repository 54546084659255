@import './variable.scss';

button {
  outline: none;
  cursor: pointer;
}

// 버튼 로딩 아이콘
.button-loading {
  padding-left: 10px;
}

// 로그인, 회원가입 버튼
.login-btn {
  width: 320px;
  height: 55px;
  margin: 33px 0 15px;
  color: #ddd;
  font-weight: 700;
  // background: url(/img/login/login_button.png) no-repeat center;
  background-color: transparent;
  box-shadow: 1px 1px 1px #333;
  border: none;
  border-radius: 28px;
  outline: none;

  // &.grey {
  //   background: url(/img/login/grey_button.png) no-repeat center;
  // }
}

// 훈련 실시, 교육 실시 버튼
.brand-square-btn {
  padding: 5px 16px;
  color: $brand;
  font-size: 14px;
  font-weight: 700;
  background-color: transparent;
  border: 1px solid $brand;
  border-radius: 3px;
  outline: none;

  &:hover {
    background-color: $brand;
    color: #333;
  }
}

// semantic-ui button CSS override
.ui.green {
  &.basic.label {
    border-color: $brand !important;
    color: $brand !important;
  }

  &.label {
    background-color: $brand !important;
  }

  &.ribbon.label {
    border-color: $brand !important;
  }

  &.button {
    background-color: $brand !important;
  }

  &.basic.button {
    color: $brand !important;
    background: transparent none !important;
  }

  &.progress {
    .bar {
      background-color: $brand;
    }
  }
}

.ui.button {
  &.bt-style-bulging {
    color: $grey800;
    background-color: $grey;
    border: 1px solid $grey;
    box-shadow: 1px 1px 1px $grey500;
    padding: 7px 15px;
    border-radius: 2px;

    &:hover {
      color: $grey900;
      border: 1px solid $grey300;
      background-image: linear-gradient(to bottom, $light-grey, $grey);
      box-shadow: 1px 1px 2px $grey600;
      border-radius: 2px;
    }

    &:active {
      border: 1px solid $dark-grey;
      box-shadow: inset 0 1px 1px $grey500;
    }

    &:focus {
      background-color: $grey;
      background-image: none;
      border: 1px solid $white;
    }
  }

  &.bt-style-gleam {
    &:hover {
      box-shadow: 1px 1px 5px $grey700;
    }

    &:active {
      //border: 1px solid $dark-grey;
      box-shadow: inset 0 1px 10px $grey600;
    }
  }

  &.bt-style-white-gleam {
    border: 1px solid $grey700;
    color: $grey800;
    background-color: $white;
    box-shadow: 1px 1px 1px $grey500;

    &:hover {
      color: $grey900;
      box-shadow: 1px 1px 3px $grey700;
    }

    &:active {
      //border: 1px solid $dark-grey;
      box-shadow: inset 0 1px 8px $grey600;
    }
  }
}

.round-grey-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 83px;
  height: 30px;
  line-height: 28px;
  margin-left: 5px;
  padding: 0 12px;
  font-size: 12px;
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 15px;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
