.table-date-picker {
  display: flex;
  align-items: center;

  img {
    margin-left: 3px;
    padding: 3px;
    border-radius: 3px;

    &:hover {
      background-color: #ddd;
    }
  }
}

.table-date-picker-tooltip {
  z-index: 2;

  .ant-tooltip-inner {
    width: 180px;
    padding: 0;
    color: rgba(0, 0, 0, 0.87);
    background-color: #fff;

    .table-date-picker-item {
      padding: 20px 15px 15px;
      border-bottom: 1px solid #ddd;

      .filter-title {
        margin-bottom: 10px;
        font-weight: 700;
      }

      .ant-radio-wrapper {
        margin: 10px 0;
        font-weight: normal;
      }

      .date-picker {
        height: 30px;

        input {
          font-size: 12px;
        }
      }
    }

    .table-search-btn {
      width: 100%;
      padding: 3px 0;
      color: rgba(0, 0, 0, 0.87);
      font-size: 12px;
      font-weight: 700;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
    }
  }

  .ant-tooltip-arrow {
    .ant-tooltip-arrow-content {
      background-color: #fff;
    }
  }
}

.table-dropdown {
  //   .ant-picker-footer-extra {}

  .date-picker-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    font-size: 13px;

    .form-field-wrap {
      width: 30%;
      margin-left: 10px;

      .ant-select-selector,
      input {
        margin: 0;
        height: 30px !important;
      }

      .ant-select-arrow {
        top: 53%;
        right: 11px;
      }
    }
  }
}
