.phishing-page-add-edit-modal {
  width: 100% !important;
  max-width: 100%;
  position: absolute;
  height: 100% !important;
  display: flex !important;
  flex-direction: column;
  padding-bottom: 0;
  letter-spacing: 0 !important;

  .ant-modal-content {
    height: 100%;
    border-radius: 0 !important;
    background-color: #21262d;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-header {
      padding: 0 !important;
      color: #fbfbfb;
      background: #1d2127 !important;
      border-radius: 0 !important;
      border-bottom: none;
    }

    .ant-modal-body {
      padding: 0 !important;
      height: calc(100% - 60px);

      .modal-content {
        display: flex;
        height: 100%;
      }
    }

    .ant-modal-footer {
      position: absolute;
      top: 0;
      right: 10px;
      height: 60px;
      display: flex;
      align-items: center;
      padding: 0 !important;
      background-color: transparent;
      border-radius: 0 !important;

      button {
        display: flex;
        align-items: center;
        min-width: auto !important;
        height: 100% !important;
        padding: 0 10px;
        color: #fbfbfb !important;
        font-size: 13px;
        font-weight: normal !important;
        border: none !important;
        border-bottom: 1px solid transparent;
        border-radius: 0 !important;
        background-color: transparent !important;
        cursor: pointer;
        box-sizing: border-box;

        &:hover {
          border-bottom: 1px solid #00ad7b !important;
        }
      }
    }
  }
}
