.test-box {
  margin-top: 15px;
  display: flex;
  padding-left: 45px;

  button {
    min-width: 125px;
    height: 45px;
    margin: 0 5px;
    color: #bbbbbb;
    font-weight: 700;
    background-color: #fff;
    border: 1px solid #bbbbbb;
    border-radius: 23px;
    padding: 0 25px;

    &.ok {
      color: #fff;
      background-color: #11a991;
      border-color: #fff;
      border: 1px solid #11a991
    }
    
    &:hover {
      box-shadow: 1px 1px 5px #616161;
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}

.connecting-row {
  margin-left: 17px;
  padding-top: 6px;

  img {
    margin-right: 10px;
  }

  .connecting-text {
    display: flex;
    line-height: 28px;
  }
}
