/* brand color: #3cb482 -> #00ad7b */
@import './variable.scss';

.brand-color {
  color: $brand !important;
}

.brand-bg {
  background-color: $brand !important;
}

// 태그 색상
.tag-red {
  background-color: $tag-red !important;
}

.tag-orange {
  background-color: $tag-orange !important;
}

.tag-yellow {
  background-color: $tag-yellow !important;
}

.tag-olive {
  background-color: $tag-olive !important;
}

.tag-lightgreen {
  background-color: $tag-lightgreen !important;
}

.tag-green {
  background-color: $tag-green !important;
}

.tag-pink {
  background-color: $tag-pink !important;
}

.tag-purple {
  background-color: $tag-purple !important;
}

.tag-violet {
  background-color: $tag-violet !important;
}

.tag-lightblue {
  background-color: $tag-lightblue !important;
}

.tag-blue {
  background-color: $tag-blue !important;
}

.tag-emerald {
  background-color: $tag-emerald !important;
}

.tag-lightpink {
  background-color: $tag-lightpink !important;
}

.tag-plum {
  background-color: $tag-plum !important;
}

.tag-darkplum {
  background-color: $tag-darkplum !important;
}

.tag-ash {
  background-color: $tag-ash !important;
}

.tag-apricot {
  background-color: $tag-apricot !important;
}

.tag-brown {
  background-color: $tag-brown !important;
}

.tag-grey {
  background-color: $tag-grey !important;
}

.tag-black {
  background-color: $tag-black !important;
}

// 태그 라벨 색상
.tag-label-red {
  color: $tag-red !important;
  border: 1px solid $tag-red !important;
}

.tag-label-orange {
  color: $tag-orange !important;
  border: 1px solid $tag-orange !important;
}

.tag-label-yellow {
  color: $tag-yellow !important;
  border: 1px solid $tag-yellow !important;
}

.tag-label-olive {
  color: $tag-olive !important;
  border: 1px solid $tag-olive !important;
}

.tag-label-lightgreen {
  color: $tag-lightgreen !important;
  border: 1px solid $tag-lightgreen !important;
}

.tag-label-green {
  color: $tag-green !important;
  border: 1px solid $tag-green !important;
}

.tag-label-pink {
  color: $tag-pink !important;
  border: 1px solid $tag-pink !important;
}

.tag-label-purple {
  color: $tag-purple !important;
  border: 1px solid $tag-purple !important;
}

.tag-label-violet {
  color: $tag-violet !important;
  border: 1px solid $tag-violet !important;
}

.tag-label-lightblue {
  color: $tag-lightblue !important;
  border: 1px solid $tag-lightblue !important;
}

.tag-label-blue {
  color: $tag-blue !important;
  border: 1px solid $tag-blue !important;
}

.tag-label-emerald {
  color: $tag-emerald !important;
  border: 1px solid $tag-emerald !important;
}

.tag-label-lightpink {
  color: $tag-lightpink !important;
  border: 1px solid $tag-lightpink !important;
}

.tag-label-plum {
  color: $tag-plum !important;
  border: 1px solid $tag-plum !important;
}

.tag-label-darkplum {
  color: $tag-darkplum !important;
  border: 1px solid $tag-darkplum !important;
}

.tag-label-ash {
  color: $tag-ash !important;
  border: 1px solid $tag-ash !important;
}

.tag-label-apricot {
  color: $tag-apricot !important;
  border: 1px solid $tag-apricot !important;
}

.tag-label-brown {
  color: $tag-brown !important;
  border: 1px solid $tag-brown !important;
}

.tag-label-grey {
  color: $tag-grey !important;
  border: 1px solid $tag-grey !important;
}

.tag-label-black {
  background-color: $tag-black !important;
  border: 1px solid $tag-black !important;
}

// 템플릿 색상
.color-bg-warning {
  background-color: $temp-warning !important;
}

.color-bg-file {
  background-color: $temp-file !important;
}

.color-bg-research {
  background-color: $temp-research !important;
}

.color-bg-phishing {
  background-color: $temp-phishing !important;
}

// 템플릿 유형별 라벨 색상
.no-margin {
  margin: 0;
}
// 시스템
.color-temp-system {
  border-color: $temp-system !important;
  color: $temp-system !important;
}

// 사용자
.color-temp-user {
  border-color: $temp-user !important;
  color: $temp-user !important;
}

// 경고안내
.color-temp-warning {
  border-color: $temp-warning !important;
  color: $temp-warning !important;
}

.color-temp-file {
  // 모의악성파일
  border-color: $temp-file !important;
  color: $temp-file !important;
}

.color-temp-research {
  // 실태조사
  border-color: $temp-research !important;
  color: $temp-research !important;
}

.color-temp-phishing {
  // 피싱유도
  border-color: $temp-phishing !important;
  color: $temp-phishing !important;
}

.color-attach-exe {
  // 첨부파일 관리 - 실행 파일
  border-color: $attach-exe !important;
  color: $attach-exe !important;
  background-color: $attach-exe-bk !important;
}

.color-attach-lnk {
  // 첨부파일 관리 - 바로가기
  border-color: $attach-lnk !important;
  color: $attach-lnk !important;
  background-color: $attach-lnk-bk !important;
}

.color-attach-doc {
  // 첨부파일 관리 - 문서파일
  border-color: $attach-doc !important;
  color: $attach-doc !important;
  background-color: $attach-doc-bk !important;
}

.color-attach-html {
  // 첨부파일 관리 - 웹문서
  border-color: $attach-html !important;
  color: $attach-html !important;
  background-color: $attach-html-bk !important;
}

.color-bg-attachFunction {
  // 첨부파일 관리 - 부가기능
  background-color: $attach-function !important;
  color: $white !important;
}

.color-bg-attachRestrict {
  // 첨부파일 관리 - 제약사항
  background-color: $attach-restrict !important;
  color: $white !important;
}
// 글자 색상
.color-orange {
  color: $orange !important;
}

.color-brown {
  color: $brown !important;
}

.color-red {
  color: $red !important;
}

.color-dark-red {
  color: $dark-red !important;
}

.color-cure {
  color: $cure !important;
}

.color-down {
  color: $down !important;
}

.color-black {
  color: $black !important;
}

.color-grey {
  color: #9c9c9c !important;
}

.color-brand-green {
  color: $brand-green !important;
}

.color-dim-grey {
  color: $dim-grey !important;
}

.color-dark-grey {
  color: $dark-grey !important;
}

.color-border-orange {
  color: $orange !important;
  border-color: $orange !important;
}

.color-border-down {
  color: $down !important;
  border-color: $down !important;
}

.color-border-red {
  color: $red !important;
  border-color: $red !important;
}

.color-border-cure {
  color: $cure !important;
  border-color: $cure !important;
}

.color-border-declare {
  color: #eea200 !important;
  border-color: $tag-yellow !important;
}

.bg-color-orange {
  background-color: $orange !important;
}

.bg-color-down {
  background-color: $down !important;
}

.bg-color-red {
  background-color: $red !important;
}

.bg-color-cure {
  background-color: $cure !important;
}

.bg-color-declare {
  background-color: $tag-yellow !important;
}

.bg-color-grey {
  background-color: #797979 !important;
}
