// 서비스 가입 - 서비스명
.white-tooltip {
  margin-left: 5px;
  cursor: pointer;

  .ant-tooltip-content {
    .ant-tooltip-arrow {
      left: 2px;

      .ant-tooltip-arrow-content {
        background-color: #fff;
      }
    }

    .ant-tooltip-inner {
      padding: 10px 15px;
      color: #525150;
      background-color: #fff;
      border-radius: 3px;
    }
  }
}

// 훈련 실시 prepare arrow
.black-tooltip {
  .ant-tooltip-inner {
    min-height: auto;
    padding: 3px 8px;
    font-size: 12px;
  }

  &.full {
    max-width: 100%;
  }
}

// 팝오버
.ant-popover {
  &.full {
    .ant-popover-content {
      max-width: 100%;
    }
  }

  .ant-popover-content {
    max-width: 240px;
    line-height: 1.4;
    border-radius: 5px;

    .ant-popover-inner {
      border-radius: 3px;

      .ant-popover-inner-content {
        padding: 12px;
      }
    }
  }
}

// 에러 툴팁 (기본은 좌측하단)
.arrow-box {
  position: absolute;
  bottom: -35px;
  display: inline;
  background: #da4845;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 700;
  font-size: 12px;
  color: white;
  z-index: 130;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 10px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #da4845;
  }

  // 우측 상단
  &.top {
    top: -30px;
    right: 0;
    bottom: auto;

    &::before {
      top: auto;
      bottom: -5px;
      left: auto;
      right: 10px;
      border-top: 6px solid #da4845;
      border-bottom: 0;
    }
  }
}

// recharts 공통 툴팁
.rechart-tooltip {
  max-width: 245px;
  padding: 7px 15px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #afafaf;

  .title {
    font-size: 12px;
    font-weight: 700;
    color: #9e9e9e;
  }

  .value {
    font-size: 15px;
    font-weight: 700;
    color: #333;
  }
}

.between-popover {
  font-size: 11px;

  .popover-row {
    display: flex;
  }

  .text {
    min-width: 80px;
  }

  .value {
    font-weight: bold;
  }
}
