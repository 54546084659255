@import '../../styles/variable.scss';

.template-side-menu {
  background-color: rgba(255, 255, 255, 0);
  margin-top: 26px;

  .template-side-menu-item {
    height: 47px;
    line-height: 35px;
    padding: 7px 20px 7px 40px !important;
    font-size: 15px;
    font-weight: 700 !important;

    div {
      display: flex;
      align-items: center;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      color: $black;
    }

    &.ant-menu-item {
      margin-top: 2px;
      margin-bottom: 2px;
    }

    &.ant-menu-item-selected {
      color: $brand !important;
      background-color: rgba(255, 255, 255, 0);
    }

    &.submenu {
      padding: 7px 20px 7px 65px !important;
      font-size: 13px !important;
      height: 30px;
      line-height: 15px;
    }
  }
}
