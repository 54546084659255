.declare-content-wrap {
  height: calc(100% - 50px);

  .declare-select {
    width: 180px;
    font-size: 13px;

    &:hover,
    &.ant-select-focused {
      .ant-select-selector {
        border-color: #ddd !important;
        box-shadow: none !important;
      }
    }

    .ant-select-selector {
      border-radius: 3px;
    }
  }

  .round-grey-button {
    &.declare-icon-label {
      justify-content: space-between;
      min-width: 70px;
      height: 26px;
      padding: 3px 11px;
      font-size: 13px;
    }
  }
}

// 신고 상세
.declare-detail {
  .detail-contents {
    margin: 35px 0 0 !important;

    .detail-value {
      word-break: break-all;
    }
  }

  .declare-detail-box {
    position: relative;
    background-color: #fcfcfc;
    border: 1px solid #ddd;
    margin: 30px 0;
    padding: 22px 22px 8px;
    border-radius: 5px;
    font-size: 14px;

    .detail-text {
      font-weight: 700;
    }

    .detail-extra-area {
      position: absolute;
      top: -15px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 20px;

      .detail-btns {
        display: flex;

        .edit-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 3px;
          width: 30px;
          height: 30px;
          background: #fff;
          border: 1px solid #ddd;
          border-radius: 50%;
          cursor: pointer;

          .anticon {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.declare-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 13px;
  color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  cursor: pointer;

  &.nis {
    border: 1px solid #080454;
    background: #080454;
  }

  &.external {
    border: 1px solid #ee4f4f;
    background: #ee4f4f;
  }

  &.exam {
    border: 1px solid #711bd1;
    background: #711bd1;
  }

  &.small {
    padding: 1px 15px;
    font-size: 12px;
  }
}

// 신고 수정 모달
.declare-edit-modal {
  .declare-textarea {
    width: 100%;
    margin-top: 5px;
    padding: 10px;
    border-color: #ddd;
    border-radius: 5px;
    outline: none;
    resize: none;
  }
}

// 신고하기 메일
.declare-mail-popover {
  .value {
    max-width: 380px;
    word-break: break-all;
  }
}
