.dashboard-chart {
  .pie-chart {
    display: flex;

    .left {
      width: 50%;

      .title {
        width: 100px;
        margin: 0 0 20px 2px;
        font-size: 16px;
        font-weight: 700;
        color: #333;
      }

      .percent {
        display: block;
        font-size: 42px;
        font-weight: 700;
        letter-spacing: -1px;

        em {
          display: inline-block;
          margin-left: 3px;
          font-style: normal;
          font-size: 14px;
          font-weight: bold;
          color: #666;
        }
      }
    }

    .right {
      .pie-chart-item {
        position: relative;

        img {
          position: absolute;
          left: 42%;
          top: 47%;
        }
      }
    }
  }
}
