@import '../../styles/common.scss';

// 사이드바 훈련 요약 리스트
.summary-list {
  position: relative;
  width: 100%;
  height: 100%;

  .summary-item {
    border-left: 4px solid transparent;
    cursor: pointer;

    &:hover,
    &.selected {
      background-color: #fff !important;
      border-left: 4px solid #039492 !important;

      .detail-bg-box {
        background-color: #ececec !important;
      }
    }

    &.edu {
      .inner-top {
        justify-content: flex-start !important;
      }
    }
  }

  .summary-list-header {
    height: 232px;
    // background-color: #f8f8f8;

    .summary-title-area {
      display: flex;
      align-items: center;
      padding: 16px 26px;
      font-weight: 700;
      border-bottom: 1px solid #e2e2e2;

      >img {
        margin-right: 10px;
      }

      span {
        width: 100%;
      }

      button {
        display: flex;
        padding: 0;
        border: 0;
        background-color: transparent;
      }
    }

    .summary-list-filter {
      margin: 0 16px;
      padding: 17px 8px 20px;
      font-size: 13px;
      border-bottom: 1px solid #e2e2e2;
    }
  }

  .summary-search-item {
    display: flex;
    align-items: center;
    height: 28px;
    cursor: pointer;

    &.active {
      .filter-icon {
        border: 3px solid #5a5a5a !important;
      }

      .filter-name {
        font-weight: 700;
      }
    }

    .filter-icon {
      width: 14px;
      height: 14px;
      margin-right: 10px;
      background-color: #f1f1f1;
      border: 3px solid #bababa;
      border-radius: 20px;
    }

    .summary-search-text {
      position: relative;
      display: flex;
      align-items: center;

      input {
        width: 100%;
        height: 28px;
        font-weight: 700;
        background: transparent;
        border: none;
        border-bottom: 1px solid #bababa;
        padding: 0 45px 0 0;
      }

      .summary-search-btns {
        position: absolute;
        right: 0;

        .search-btn {
          padding: 3px;
          margin-right: 3px;

          &:hover {
            background-color: #dddddd;
            border-radius: 3px;
          }
        }
      }
    }
  }

  .summary-list-content {
    height: calc(100% - 232px);
    overflow-y: auto;

    .random-group {
      border-bottom: 4px solid #e6e6e6;

      &.selected {
        border-bottom: 4px solid #14ae9e;

        .random-title {
          background-color: #14ae9e;
          color: #fff;
          border-left: 4px solid #14ae9e;
        }

        .summary-item {
          border-right: 4px solid #14ae9e;
          border-left: 4px solid #14ae9e !important;
        }
      }

      .random-title {
        padding: 6px 10px;
        font-size: 12px;
        font-weight: 700;
        background-color: #e6e6e6;
        cursor: pointer;
        border-left: 4px solid #b2b2b2;
      }

      .summary-item {
        border-left: 4px solid #b2b2b2 !important;

        &.selected {
          border-left: 4px solid #14ae9e !important;
        }
      }
    }

    .summary-item-wrap {
      position: relative;
      padding: 0 12px;

      &.group {
        background-color: #f1f1f1;
        border-left: 4px solid #b2b2b2;
      }

      .summary-item-inner {
        padding: 15px 2px;
        border-bottom: 1px solid #e2e2e2;

        .inner-top {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .send-icon,
          .infection-icon {
            position: relative;
            margin-right: 5px;
            top: -1px;
          }

          .group-count {
            width: 23px;
            height: 16px;
            margin-top: 3px;
            margin-right: 3px;
            line-height: 16px;
            color: #ffffff;
            font-size: 10px;
            font-weight: 700;
            text-align: center;
            background: #a1a1a1;
            border-radius: 5px;
          }

          .exam-text {
            flex: 1;
            margin-right: 4px;
            font-weight: 700;
            word-break: break-all;
          }

          .exam-buttons {
            display: flex;

            img {
              margin: 2px;

              &:hover {
                background-color: #dddddd;
                border-radius: 3px;
              }
            }
          }
        }

        .inner-bottom {
          .summary-detail {
            font-size: 12px;

            .detail-bg-box {
              margin: 10px 0;
              padding: 10px;
              background-color: #e2e2e2;
              border-radius: 5px;

              >div {
                margin-bottom: 5px;
              }
            }

            .detail-title {
              min-width: 56px;
              color: #333;
              font-weight: 700;

              .anticon {
                margin-left: 3px;
              }
            }

            .detail-value {
              color: #888;
            }

            .detail-content {
              margin-bottom: 5px;

              .detail-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 7px;
                border: 1px solid transparent;

                &:hover:not(.target) {
                  border: 1px solid #aeaeae;
                  border-radius: 3px;
                }

                .detail-content-value {
                  min-width: 45px;
                  padding: 5px 4px 5px;
                  color: #333;
                  font-size: 11px;
                  font-weight: 700;
                  text-align: right;
                }
              }
            }
          }

          .summary-progress {
            position: relative;

            .progress-status {
              position: absolute;
              top: 4px;
              left: 0;
              display: flex;
              justify-content: center;
              min-width: 2.5em;
              height: 100%;
              color: #fff;
              font-size: 0.8em;
              font-weight: 700;
              float: right;

              .status-text {
                flex: 1;
                text-align: right;
                padding-right: 8px;
              }
            }
          }
        }
      }
    }

    .no-exam-summary {
      margin-top: 38px;
      color: #121212;
      font-size: 15px;
      font-weight: 700;
      text-align: center;
    }
  }
}

// 훈련 요약 드롭다운
.summary-dropdown {
  .ant-dropdown-menu-item {
    font-size: 12px;
    padding: 5px 15px;
  }
}

// 훈련 요약 수정
.summary-edit {
  .content-item {
    margin-top: 14px;

    &:first-child {
      margin: 0;
    }

    .setting-box {
      padding: 10px 15px 10px 30px;

      &.disabled {
        opacity: 0.6;
        pointer-events: none;
      }

      .setting-row {
        display: flex;
        align-items: center;

        &:last-child {
          align-items: end;
          margin-top: 5px;

          .input-title {
            margin-top: 10px;
          }
        }

        .input-title {
          width: 110px;
        }

        .send-value {
          flex: 1;
          margin-left: 15px;
        }
      }

      .flex {
        justify-content: space-between;
      }

      .slider-status {
        width: 44px;
        height: 23px;
        line-height: 23px;
        color: #fff;
        font-size: 11px;
        font-weight: 700;
        text-align: center;
        background-color: #c5c5c5;
        border-radius: 12px;
      }
    }

    .send-value {

      .period-area,
      .slider-area {
        @include slider(#ffc64c, #f89b0a);
        padding: 0 23px;
      }

      .period-area {
        display: flex;
        align-items: center;

        .period-text {
          width: 50px;
          text-align: center;
          color: #414141;
          font-size: 17px;
        }

        img {
          cursor: pointer;
        }
      }
    }

    .send-period-text {
      margin-top: 8px;
      color: #14ae96;
      font-size: 13px;
      font-weight: 700;
    }
  }

  .start-exam-border-box {
    border: 1px solid #ddd;
    border-radius: 5px;

    .header-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 18px;
      background-color: #fbfbfb;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-bottom: 1px solid #dedede;

      .title {
        display: flex;
        align-items: center;

        img {
          margin-left: 5px;
        }
      }
    }
  }
}

// 개별 훈련 차트
.exam-result-chart {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 272px;
  padding-top: 15px;

  .chart-item {
    &.left {
      margin-right: 20px;
      padding: 15px 15px 15px 25px;
      text-align: center;
      border: 1px solid #ddd;
      border-radius: 15px;
      box-shadow: 5px 5px 10px #f2f2f2;

      .chart-info {
        .chart-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 185px;
          padding: 4px 0;

          .row-text {
            display: flex;
            align-items: center;
            color: #888;
            font-size: 13px;
            font-weight: 700;

            img {
              margin-right: 5px;
            }
          }

          .row-value {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            min-width: 70px;

            .value-count {
              padding-left: 10px;
              font-size: 16px;
              font-weight: 700;
            }

            .value-unit {
              margin-left: 5px;
              color: #bdbdbd;
              font-size: 11px;
            }
          }
        }
      }

      .percent {
        display: inline-flex;
        align-items: center;
        margin-top: 8px;
        padding: 4px 17px;
        color: #fff;
        font-size: 13px;
        background-color: #afafaf;
        background-image: linear-gradient(-45deg, #9e9e9e, #bdbdbd);
        border-radius: 20px;

        .percent-number {
          position: relative;
          top: -1px;
          margin: 0 2px 0 15px;
          font-size: 18px;
          font-weight: 700;
        }
      }
    }

    &.center {
      margin-right: 40px;
    }

    &.right {
      .chart-tab-header {
        display: flex;
        width: 360px;
        font-size: 12px;
        font-weight: 700;
        background-color: #f6f6f6;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .tab-name {
          flex: 1;
          padding: 10px;
          text-align: center;
          border: 1px solid transparent;
          border-bottom: 1px solid #e5e5e5;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          cursor: pointer;

          &.active {
            border: 1px solid #e5e5e5;
            border-bottom: 1px solid transparent;
            background-color: #fff;
          }
        }
      }

      .chart-tab-content {
        display: flex;
        height: 195px;
        overflow-y: auto;
        padding: 9px 5px;
        font-size: 12px;
        background-color: #fff;
        border: 1px solid #e5e5e5;
        border-radius: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 0;

        .no-data {
          margin: auto;
          color: #888;
          font-size: 16px;
          text-align: center;

          img {
            margin-bottom: 10px;
          }
        }

        .bar-title {
          font-size: 18px;
          font-weight: 700;
        }

        .bar-content {
          font-size: 12px;
        }
      }
    }
  }
}

// 원 차트
.chart-legend {
  min-width: 22px;
  height: 20px;
  color: #888;
  font-size: 11px;

  .color {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 4px;
    margin-top: 1px;
    border-radius: 4px;
  }

  &.flex-between {
    display: flex;
    justify-content: space-between;
  }
}

// 훈련 리스트
.exam-content-wrap {
  // height: 100%;
  height: calc(100% - 50px);

  &.by {
    .common-content-list {
      height: 100%;
    }

    .long-column {
      flex: 3;
      color: #000;
    }

    .center-column {
      flex: 1;
      justify-content: center;
      text-align: center;

      .ant-tag {
        margin: 0;
      }
    }

    .epoch-column {
      width: 150px;
      justify-content: center;
      text-align: center;
    }
  }

  .content-list-item {
    .exam-list-item {
      div {
        display: flex;
        align-items: center;
      }

      &.deleted {
        div {
          opacity: 0.8;

          .email-column {
            text-decoration: line-through;
            color: #db2828;
          }
        }
      }

      // 상태바
      .exam-item-progress {
        width: 30px;
        margin: 0 5px;

        .ant-progress-outer,
        .ant-progress-inner {
          width: 30px;
          padding: 0;
        }

        .ant-progress-inner {
          .ant-progress-bg {
            min-width: 0;
          }
        }
      }

      .status-text {
        &.tag {
          padding: 0 !important;
        }
      }
    }
  }
}

// 감염pc, 신고,교육 태그
.exam-extra-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 70px;
  height: 26px;
  margin-right: 10px;
  padding: 3px 10px !important;
  color: #9c9c9c;
  font-size: 12px;
  border: 1px solid #9c9c9c;
  border-radius: 13px;

  &.exist-infection {
    border: 1px solid #db2828;
    color: #db2828;
  }

  &.exist-declare {
    border: 1px solid #ffcc00;
    color: #eea200;
  }

  &.exist-edu {
    border: 1px solid #ff6744;
    color: #ff6744;
  }

  &.small {
    min-width: 60px !important;
    height: 22px;
    margin: 0;
    font-size: 11px;
  }
}

.target-company {
  display: flex;
  text-align: left;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 0 !important;

  &.detail {
    max-width: 200px;
  }

  .division,
  .position {
    display: block;
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 5px;
    display: block !important;
  }
}

.download-img {
  margin-left: 3px;
}

// 훈련 삭제 모달
.exam-delete-modal {
  .modal-item-list {
    align-items: flex-start !important;
  }
}

// 보고서 다운로드 모달
.exam-report-download-modal {
  .input-title {
    margin-bottom: 5px;
  }

  form {
    margin-top: 20px;
  }

  .white-border-box {
    padding: 15px 20px;
    font-size: 13px;
    border: 1px solid #e4e4e4;
    border-radius: 5px;

    .box-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .qmark-img {
      position: relative;
      top: 1px;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .error-message {
    margin-top: 5px;
    font-size: 12px;
  }
}

// 메일 발송 실패 사유 모달
.send-fail-modal {
  .ant-modal-footer {
    display: none;
  }

  .fail-content {
    margin: 10px 0;
  }
}

// 태그 만들기 모달
.exam-add-tag-modal {
  .modal-item-list {
    margin: 10px 0 !important;
  }

  .arrow-box {
    font-size: 12px !important;
  }
}

// 훈련 상세
.exam-detail {
  .reserve-icon {
    margin-right: 5px;
  }

  .detail-progress {
    position: relative;
    width: 150px;

    .progress-status {
      position: absolute;
      top: 4px;
      left: 0;
      display: flex;
      justify-content: center;
      min-width: 2.5em;
      height: 100%;
      color: #fff;
      font-size: 11px;
      font-weight: 700;
      float: right;

      .status-text {
        flex: 1;
        text-align: right;
        padding-right: 8px;
      }
    }
  }

  .exam-tag {
    .ant-tag {
      padding: 0 6px;
      font-size: 11px;
      line-height: 17px;
    }
  }

  .attach-name {
    margin: 5px 0;
    word-break: break-all;
  }

  .anticon-info-circle {
    margin-left: 5px;
  }

  .chart-item {
    .chart-legend {
      color: #333;
      font-size: 13px;

      .message {
        width: 100px;
      }
    }
  }

  .detail-contents {
    .align-center {
      align-items: center !important;
      margin-bottom: 6px;
    }

    .detail-tag-item {
      border-bottom: 1px solid #f2f2f2;
      padding: 7px 0 5px;

      .detail-list-step {
        display: flex;
        align-items: center;
        height: 31px;
        margin: 6px 0;

        .status-detail-text {
          width: 70px;
          margin-right: 5px;
          color: #14ae96;
          font-size: 13px;
          font-weight: 700;
        }
      }
    }

    .detail-text {
      a {
        color: inherit;
      }
    }

    // 템플릿별 대상자 정보 영역
    .detail-target-item {
      padding: 10px 0 5px;
      border-bottom: 1px solid #f2f2f2;
      cursor: default;

      &:first-child {
        padding-top: 0;
      }

      .target-detail {
        display: flex;
        justify-content: space-between;
        align-items: center !important;
        padding: 3px 0 !important;

        .exam-info {
          display: flex;
          align-items: center;
          width: 70px;

          &:first-child {
            img {
              padding: 5px 8px;
            }
          }

          .exam-value {
            margin-bottom: 1px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

.detail-area {
  .inner-item {
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }

    .inner-title {
      font-weight: 700;
      font-size: 12px;
      margin-bottom: 10px;
    }

    .inner-contents {
      margin: 2px 0 0 10px;

      .inner-row {
        display: flex;
        margin-top: 5px;
        font-size: 12px;

        .inner-row-title {
          width: 100px;
        }

        .inner-row-value {
          width: calc(100% - 100px);
          padding-left: 5px;
        }

        &.count-row {
          margin-top: 10px;
          justify-content: space-between;
        }

        .dot-title {
          position: relative;
          display: flex;
          align-items: center;

          .dot {
            position: absolute;
            left: -5px;
          }
        }

        .row-grey {
          margin-left: 5px;
          color: #777;
        }
      }
    }
  }

  .infection-target-item {
    .infection-target-text {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        cursor: pointer;
      }
    }

    .infection-target-contents {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
    }

    .infecion-detail-box {
      margin: 13px 0 3px;
      padding: 18px 15px 10px;
      background-color: #f6f6f6;
      border-radius: 5px;
    }
  }
}