.user-add-edit-modal {
  .email-message-area {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    font-size: 12px;

    button {
      margin-left: 5px;
      color: #fff;
      font-size: 11px;
      background-color: #5e5e5e;
      border-radius: 5px;
      border: none;

      &.resend-btn {
        background-color: #00ad7b;
      }
    }
  }

  .admin-menu {
    .menu-title {
      margin: 20px 0 5px;
      font-weight: 700;
    }

    .account-row {
      display: flex;

      .activate {
        flex: 3 !important;
        margin-right: 5px;
      }

      .enable {
        flex: 1;
      }
    }
  }
}
