.exam-random-chart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  height: 272px;
  margin: 0 auto;

  .pie-chart-item {
    position: relative;
    top: -20px;

    .recharts-wrapper {
      margin: 0 auto;
    }

    .chart-icon {
      position: relative;
      top: 73px;
      text-align: center;
    }

    .random-chart-result {
      display: flex;
      align-items: center;
      justify-content: center;

      .random-chart-text {
        margin-right: 15px;
      }

      .random-chart-count {
        .random-value-count {
          font-size: 16px;
          font-weight: 700;
        }

        .random-value-unit {
          color: #888;
          font-size: 12px;
        }
      }
    }
  }
}
