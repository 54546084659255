.common-dimmer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .dimmer-icon {
    margin-right: 5px;
  }

  .dimmer-text {
    color: #333;
    font-size: 18px;
  }
}
