.user-license-set {
  margin: 1rem 0;
  .segment-box {
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
    overflow: hidden;

    & > .license-expired {
      background-color: #dedede;
    }
  }

  .license-title {
    display: flex;
    align-items: center;
    line-height: 26px;
    padding: 15px 28px;
    border-bottom: 1px solid #dedede;
    justify-content: space-between;
    background-color: #f9f9f9;

    .title-box {
      height: 26px;
      flex: auto;
      margin-left: 8px;
      display: flex;
      align-items: center;

      .license-user-title {
        padding: 0 30px 0;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 2px;
      }

      .license-process {
        font-size: 16px;
        font-weight: 700;
      }

      .license-request {
        color: #ffb400;
      }

      .license-use {
        color: #11a991;
      }

      .license-ready {
        color: #2185d0;
      }

      .license-expired {
        color: #333333;
      }
    }

    .expire-soon {
      border: none;
      display: flex;
      right: 18px;
      color: #c4282d !important;
      background-color: transparent !important;
      padding: 0.5833em 0.833em;
      font-weight: 700;
      line-height: 1;

      &.ui.basic.red.label {
        background-color: transparent !important;
      }

      .image {
        margin-right: 3px;
        width: 13px !important;
        height: 13px !important;
      }
    }
  }

  .license-info {
    padding: 25px 40px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .info-row {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 8px 0;

      &.examLimitInfo-area {
        align-items: flex-start;

        // .info-title-license {
        //   display: inline-block;
        //   font-size: 14px;
        //   font-weight: bold;
        //   color: #14ae96;
        //   padding: 0 22px 0 0;
        //   text-indent: 4px;
        //   background: url(/img/tag/ico_open.png) no-repeat right center;

        //   &.active {
        //     background: url(/img/tag/ico_close.png) no-repeat right center;
        //   }
        // }
      }

      .info-wrap {
        display: flex;
      }

      .info-title {
        width: 140px;
        min-width: 120px;
        font-weight: 700;
        color: #333333;
        // background-image: url(/img/setting/list-dot.png);
        background-position: left;
        background-repeat: no-repeat;
        padding-left: 8px;
      }

      .info-progress-group {
        display: flex;
        flex: 1;
      }

      .info-value {
        font-weight: 700;
        color: #333333;
        display: flex;
        align-items: center;

        &.examLimitInfo {
          width: 600px;
          font-size: 12px;

          .ant-collapse {
            .ant-collapse-item {
              .ant-collapse-header {
                padding: inherit;
                font-size: 14px;
                font-weight: bold;
                color: #14ae96;

                .ant-collapse-arrow {
                  padding: 0 !important;
                  position: initial !important;
                }
              }
            }
          }

          .accordion > .title {
          }

          .detail-title {
            width: 105px;
            display: inline-block;
          }

          .detail-content {
            max-width: 200px !important;

            .template-user-label {
              font-size: 9px;
              padding: 0 4px;
              border-radius: 5px;
              line-height: 18px;
              height: 20px;
            }
          }

          // 라이센스 상세보기
          .content {
            background-color: #fcfcfc;
            padding: 21px 20px !important;
            margin-top: 13px;
            border: 1px solid #e8e8e8;
            border-radius: 5px;

            .content-list {
              margin: 16px 0 0 0;
              padding-bottom: 13px;
              border-bottom: 1px solid #e8e8e8;

              & > div {
                display: flex;
                padding-bottom: 5px;

                &:last-child {
                  padding-bottom: 0 !important;
                }

                & > span {
                  font-size: 12px;
                  max-width: 240px;
                  word-break: break-all;
                }
              }
            }

            div:first-child {
              .content-list {
                margin-top: 0 !important;
              }
            }

            div:last-child {
              .content-list {
                padding-bottom: 0 !important;
                border-bottom: none !important;
              }
            }
          }
        }
      }

      .info-extra {
        flex: 1;
        padding: 0 0 0 15px;
        display: flex;
        align-items: center;
        position: relative;
        .license-progress {
          width: 140px;

          .ant-progress-inner {
            vertical-align: baseline;

            .ant-progress-bg {
              margin: 0 10px 0 0;
              flex: 1;
              max-width: 140px;
              height: 10px !important;
            }
          }
        }

        .progress {
          width: 44px;
          height: 23px;
          background-color: #cccccc;
          color: #ffffff;
          line-height: 22px;
          text-align: center;
          border-radius: 12px;
          top: 0;
          left: 152px;
          margin-left: 10px;
        }
      }
    }
  }
}
