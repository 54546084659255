.service-share-title {
  margin-left: 2px;
  font-size: 16px;
  font-weight: 700;
}

.service-share-bt {
  margin: 10px 0 30px 0;
  button {
    min-width: 125px;
    height: 45px;
    margin: 5px 0;
    padding: 0 30px;
    color: white;
    font-weight: 700;
    font-size: 12px;
    background-color: #11a991;
    border: 1px solid #11a991;
    border-radius: 23px;
    &:hover {
      box-shadow: 1px 1px 5px #616161;
    }
  }
}

.service-share-content {
  width: 600px;
  // height: 300px;
  padding: 45px 60px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;

  .service-share-content-area {
    display: flex;

    .service-share-img {
      margin-top: 10px;
    }

    .service-share-content-text {
      max-width: 300px;
      margin-left: 60px;

      .service-share-content-text-header {
        font-size: 1.15em;
        font-weight: 700;
      }
    }
  }

  .service-share-notice {
    color: #66928b;
    font-size: 13px;
    background: #ffffff;
    border: none;
    margin-top: 30px;
  }
}

.sharing-user {
  margin: 10px 0;
  .shared-users {
    padding: 13px 20px !important;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 5px;
  }
  & > div:last-child {
    .share-content-box {
      height: 84px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);

      .share-box {
        height: inherit;
        flex: 1;
        margin-bottom: 0;
        display: flex;
        align-items: center;

        .share-area {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: calc(100% - 80px);
          height: inherit;
          margin-top: 3px;
          font-size: 13px;
        }

        .default-image {
          display: inline-block;
          border-radius: 59px;
          margin-right: 15px;
          width: 59px;
          height: 59px;
          // background: url(/img/share/big-share-off.png) no-repeat center center;
        }

        .content {
          height: auto;
          font-weight: 700;
          font-size: 16px;
        }

        .sub-content {
          margin-top: 3px;
          font-size: 13px;
          color: rgba(0, 0, 0, 0.6);
          font-weight: 400;
        }
      }

      button {
        min-width: 90px;
        height: 45px;
        margin: 0 5px;
        color: white;
        font-weight: 700;
        font-size: 12px;
        background-color: #5e5e5e;
        border: none;
        border-radius: 23px;
        &:hover {
          box-shadow: 1px 1px 5px #616161;
        }
      }
    }

    .share-content-box:last-child {
      border: none !important;
    }
  }
}

.share-switch-wrap {
  display: flex;
  align-items: center;
  margin-top: 20px;

  .share-label {
    font-weight: 700;
    text-indent: 10px;
  }
}

.save-result {
  margin-top: 9px;
  font-size: 12px;
  color: #14ae96;
}
