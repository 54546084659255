// 상세 SubComponent
.react-table-td-sub-area {
  position: relative;
  padding: 10px;
  background-color: #f3f3f3;

  &.systemLog-content {
    padding: 15px;

    .systemLog-title {
      font-weight: bold;
    }

    .systemLog-res {
      & > div {
        margin-top: 8px;
      }
    }
    
    pre {
      display: block;
      padding: 9.5px;
      margin: 0 0 10px;
      font-size: 13px;
      line-height: 1.42857143;
      color: #333;
      word-break: break-all;
      word-wrap: break-word;
      background-color: #f5f5f5;
      border: 1px solid #ccc;
      border-radius: 4px;
      
      font-family: inherit !important;
      margin: 5px 0 0 0;
      white-space: pre-wrap;
  
      &:last-child {
        margin-bottom: 15px !important;
      }
    }
  }
}
