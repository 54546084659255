.ant-modal-root {
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .ant-modal {
    // 모달 너비는 클래스로 지정하여 사용
    &.modal-464 {
      width: 464px !important;
    }

    &.modal-665 {
      width: 665px !important;
    }

    &.modal-965 {
      width: 965px !important;
    }

    // &.close-black {
      // .ant-modal-close {
        // background-image: url(/img/modal/close_black.png) !important;
      // }
    // }

    .ant-modal-content {
      width: inherit;
      border-radius: 15px;

      // .ant-modal-close {
        // top: 23px;
        // right: 23px;
        // width: 14px;
        // height: 13px;
        // background-image: url(/img/modal/close.png);
        // background-position: 50% 50%;

        // .ant-modal-close-x {
        //   display: none;
        // }
      // }

      .ant-modal-header {
        padding: 18px 35px;
        background: linear-gradient(90deg, #14ae96 0, #0a8076);
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;

        .ant-modal-title {
          color: #fff;
          font-size: 15px;
        }
      }

      .ant-modal-body {
        position: relative;
        padding: 35px 40px;

        .modal-content {
          // remove bootstrap style
          box-shadow: none;
          border: none;

          label {
            font-weight: normal;
          }

          .modal-text {
            font-weight: 700;
            line-height: 22px;

            .modal-text-title {
              padding: 0 10px 20px;
            }
            &.center {
              text-align: center;
            }
          }

          .template-detail-box {
            background-color: #f9f9f9;
            border-radius: 12px !important;
            margin: 0 auto 20px !important;
            box-shadow: 0 0 0 1px #e4e4e4 inset, 0 0 0 0 transparent;
            padding: 1em 1.5em;

            .template-detail-item {
              display: flex;
              justify-content: flex-start;
              margin-bottom: 6px;
              list-style: circle !important;

              .template-detail-item-title {
                min-width: 100px;
              }
            }
            .ant-list-item {
              position: relative;
              padding: 0 0 0 0 !important;
              display: flex;
              font-weight: 400;
            }
          }

          .modal-explain-text {
            width: 384px;
            margin: 0 auto;
            padding: 0 10px 20px;
            font-weight: 700;
            line-height: 22px;
          }

          .modal-border-box {
            padding: 10px;
            background-color: #f9f9f9;
            border: 1px solid #e4e4e4;
            border-radius: 12px;

            .modal-item-list {
              display: flex;
              align-items: center;
              margin: 4px 0;
              padding: 0 30px;
              font-size: 12px;

              .item-title {
                min-width: 100px;
              }

              div {
                font-size: 14px;
              }
            }

            // 복제 모달 서비스번호
            .service-no-area {
              padding: 0 15px;
            }
          }

          .switch-wrap {
            display: flex;
            align-items: center;
            margin-top: 16px;
            font-size: 14px;

            .ant-switch {
              margin-right: 10px;
            }
          }
        }
      }

      .ant-modal-footer {
        padding: 0 15px 35px;
        text-align: center;
        border: none;
        border-radius: 15px;

        button {
          min-width: 125px;
          height: 45px;
          margin: 0 5px;
          color: #bbbbbb;
          font-weight: 700;
          background-color: #fff;
          border: 1px solid #bbbbbb;
          border-radius: 23px;

          &.ok {
            color: #fff;
            background-color: #11a991;
            border-color: #fff;
          }

          &.grey {
            background-color: #787878;
          }

          &.disabled {
            pointer-events: none;
          }
        }
      }
    }
  }
}
