.tag-sidebar {
  padding: 10px;

  .tag-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px 10px;

    .tag-header-btns {
      .header-icon {
        margin: 0 2px;
        border-radius: 3px;
        opacity: 0.6;
        cursor: pointer;

        &:hover {
          background-color: #fff;
        }

        &.selected {
          background-color: #fff;
          opacity: 1;
        }

        &.anticon-gold {
          width: 19px;
          height: 19px;
          position: relative;
          top: 2px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .tag-search {
    padding: 5px 15px 10px;
  }

  .tag-inner {
    height: calc(100% - 40px);
    padding: 0 5px;
    overflow-y: auto;

    &.large {
      height: calc(100% - 80px);
    }

    .tag-list-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      padding: 12px;
      font-size: 14px;
      font-weight: 700;
      background-color: #fff;
      border: 2px solid transparent;
      border-radius: 20px;
      cursor: pointer;

      &:hover,
      &.selected {
        border-color: #14ae9e;
      }

      &.delete {
        margin-top: 20px;
        color: #b2b2b2;
        background-color: transparent;
        border: 2px solid #dcdcdc;

        &:hover {
          border-color: #b2b2b2;
        }
      }
    }
  }
}

.tag-item {
  margin: 10px 0;
  padding: 11px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 20px;

  .tag-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    font-weight: 700;

    .arrow-icon {
      padding: 3px;
      font-size: 10px;
      cursor: pointer;
    }
  }

  .tag-content {
    margin-top: 5px;
    padding: 0 5px;

    .ant-tag.color-tag {
      display: inline-flex;
      align-items: center;
      max-width: 160px;
      margin: 5px;
      cursor: pointer;
      border-bottom: 1px solid;

      &.selected {
        color: #fff !important;
      }

      .underline {
        border-bottom: 1px solid;
      }

      .tag-edit-btns {
        max-width: 140px;

        .form-field-wrap {
          .validate-error {
            background: none !important;
            border-color: inherit !important;
          }
        }

        .tag-btn-group {
          margin-left: 5px;
          opacity: 0.9;

          .anticon {
            margin-right: 0;
            padding: 3px;
            border-radius: 3px;

            &:hover {
              background-color: #ddd;
              opacity: 1;
            }
          }
        }
      }
    }

    .tag-count {
      display: inline-flex;
      height: 13px;
      margin-left: 3px;
      padding: 0 3px;
      font-size: 10px;
      border: 1px solid;
      border-radius: 4px;

      .number {
        position: relative;
        bottom: 5px;
      }
    }
  }
}

// 태그 색상 팔레트
.tag-color-palette {
  width: 140px;
  padding: 7px;
  text-align: center;
  white-space: pre-wrap;
  background-color: #fff;
  z-index: 999;

  &.tag-add-full {
    width: 100%;

    .ant-btn {
      margin: 10px;
    }
  }

  .ant-btn {
    width: 36px;
    height: 36px;
    margin: 3px;
    border: none;
    box-shadow: 0px 2px 8px #00000083;

    &:hover,
    &:focus {
      color: transparent;
      border-color: transparent;
    }

    .anticon {
      color: #fff;
      margin: 0 auto;
      transition: none;
    }
  }
}