.search-filter-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  padding: 10px 0;

  .filter-left-area {
    display: flex;
    align-items: center;
    font-size: 12px;

    .all-checkbox-area {
      margin-left: 0;

      .check-label {
        margin-left: 5px;
        color: rgba(0, 0, 0, 0.87);
      }

      .filter-check {
        width: 14px;

        &.image {
          position: relative;
          top: 1px;
        }

        .ant-checkbox-inner {
          width: 14px;
          height: 14px;
          background-color: #dbdbdb;
        }
      }

      // .ant-checkbox-checked {
      //   .ant-checkbox-inner {
      //     background-color: #a7a7a7;

      //     &::after {
      //       // background: url(/img/exam/list_check_on.png) no-repeat center;
      //     }
      //   }
      // }

      // .ant-checkbox-indeterminate {
      //   .ant-checkbox-inner {
      //     &::after {
      //       // background: url(/img/exam/list_check_each.png) no-repeat center;
      //     }
      //   }
      // }

      // .ant-checkbox-inner {
      //   width: 14px;
      //   height: 14px;
      //   background-color: #dbdbdb;
      //   border: none;

      //   &::after {
      //     top: 0;
      //     left: 0;
      //     width: 100%;
      //     height: 100%;
      //     border: none;
      //     border-radius: 3px;
      //     transform: none;
      //   }
      // }
    }

    .extra-buttons {
      .round-grey-button {
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &.tag {
          color: #757575;
          font-weight: 700;

          .plus-icon {
            display: inline-block;
            width: 12px;
            height: 12px;
            line-height: 9px;
            margin-right: 5px;
            color: #fff;
            font-size: 15px;
            background-color: #919191;
            border-radius: 2px;

            &::before {
              content: '+';
              display: flex;
              justify-content: center;
              height: 100%;
              margin-right: 1px;
            }
          }
        }
      }
    }
  }

  .filter-right-area {
    display: flex;
    align-items: center;

    .sort-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      //   width: 33px;
      //   height: 32px;
      padding: 8px 6px;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: #f6f6f6;
      }
    }

    .filter-select {
      width: 114px;
      height: 32px;

      .ant-select-selector {
        padding: 0 10px;
        color: #757575;
        font-size: 12px;
        border-color: #ddd;
        border-radius: 3px;
        font-weight: 700;

        .ant-select-selection-placeholder {
          color: #757575;
        }
      }

      .ant-select-arrow {
        top: 47%;
      }

      &:hover,
      &.ant-select-focused {
        .ant-select-selector {
          border-color: #ddd !important;
          box-shadow: none !important;
        }
      }

      &.ant-select-open {
        .ant-select-selection-item {
          color: #757575;
        }
      }
    }

    .filter-item {
      margin-left: 5px;
    }

    .filter-area {
      width: 114px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      color: #757575;
      font-size: 12px;
      border: 1px solid #ddd;
      border-radius: 3px;
      font-weight: 700;
      cursor: pointer;

      &.active {
        color: #128687;
        border-color: #85cdc2;
        background-color: #d2eae9;
      }

      .number-box {
        position: relative;
        right: -4px;
        width: 26px;
        height: 18px;
        color: #fff;
        text-align: center;
        background-color: #14ae9e;
        border-radius: 9px;
      }
    }

    .filter-input {
      position: relative;
      display: flex;
      align-items: center;

      .filter-search-input {
        width: 225px;
        height: 32px;
        padding: 0 30px 0 10px;
        border: 1px solid #ddd;
        border-radius: 3px;
        font-size: 12px;

        &.active {
          border-color: #85cdc2;
        }
      }

      .search-icon {
        position: absolute;
        right: 0;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .filter-cancel-icon {
      width: 36px;
      height: 36px;
      line-height: 32px;
      text-align: center;
      cursor: pointer;

      &:hover {
        border-radius: 50%;
        background-color: #f6f6f6;
      }
    }
  }
}

.filter-dropdown {
  .ant-select-item {
    color: #757575;
    font-size: 12px;

    &.ant-select-item-option-selected {
      background-color: rgba(0, 0, 0, 0.03) !important;
    }
  }
}

.filter-popover {
  &.single {
    .filter-popover-footer {
      display: block !important;

      .filter-footer-reset {
        padding: 0 5px 10px;
      }
    }
  }

  .ant-popover-content {
    max-width: 100% !important;
  }

  .ant-popover-inner-content {
    padding: 15px !important;

    .filter-popover-content {
      display: flex;
      padding-bottom: 15px;

      .filter-popover-item {
        min-width: 165px;
        height: 217px;
        padding-left: 15px;
        border-right: 1px solid #d9d9d9;
        overflow-y: auto;

        &:first-child {
          padding: 0;
        }

        &:last-child {
          border-right: none;
        }

        .filter-popover-title {
          padding-bottom: 10px;
          font-weight: 700;
        }

        .filter-popover-row {
          display: flex;
          align-items: center;
          padding: 6px 0;
          cursor: pointer;

          .filter-popover-text {
            width: 120px;
            margin-left: 8px;
            font-size: 13px;
            word-break: break-all;
          }
        }
      }
    }

    .filter-popover-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 12px 0;
      border-top: 1px solid #d9d9d9;

      .filter-footer-reset {
        color: #f24343;
        font-size: 13px;
        cursor: pointer;
      }

      .filter-footer-btn-area {
        display: flex;
        font-size: 12px;

        .footer-btn {
          min-width: 82px;
          height: 30px;
          color: #bbb;
          font-weight: 700;
          background-color: transparent;
          border: 1px solid #bbb;
          border-radius: 23px;

          &.ok {
            margin-left: 5px;
            color: #fff;
            background-color: #11a991;
            border-color: #11a991;
          }
        }
      }
    }
  }
}
