.custom-editor {
  padding-top: 5px;
  user-select: none;

  &.disable-toolbar {
    .note-toolbar {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .panel-heading.note-toolbar .note-para .dropdown-menu {
    min-width: 212px;

    .note-btn-group {
      margin-right: 0;
    }
  }

  .note-editor {
    &.note-frame {
      border: 1px solid #e0e0e0;
      margin-bottom: 0;
    }

    .note-toolbar {
      background-color: transparent;
      border-bottom: 1px solid #e0e0e0;

      .note-btn {
        border: 1px solid #e0e0e0;
        &:hover {
          background-color: #e0e0e0;
        }
      }

      .note-check {
        .li a i {
          visibility: visible;
        }
        .checked {
          color: #4183c4;
        }
      }
    }

    .note-editing-area {
      .note-handle {
        display: none;
      }

      .note-codable {
        background-color: #ffffff;
        color: #000000;
      }

      .note-editable {
        table {
          width: inherit;
          td {
            padding: 0;
            border: none;
          }
        }
      }
    }

    .note-popover.popover {
      display: none !important;
    }

    .tooltip {
      background-color: transparent;
    }
  }
}

.modal.in {
  z-index: 1111;

  &.note-modal {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .modal-dialog {
    position: relative;
    max-width: 500px;
    top: calc(50% - 193px);

    .modal-content {
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }

    .modal-header {
      color: #ffffff;
      background-color: #14ad95;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;

      .modal-title {
        font-size: 15px;
        font-weight: 700;
      }

      .close {
        display: block;
        color: #ffffff;
        outline: none;
        text-shadow: none;
        opacity: 0.5;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .modal-body {
      position: relative;
      .note-group-select-from-files {
        .note-form-label {
          width: 100%;
          padding: 30px 0;
          text-align: center;
          color: #838383;
          border: 1px solid #dddddd !important;
          border-radius: 5px;
          cursor: pointer;
        }

        .note-image-input {
          display: none;
        }

        .extension-message {
          font-size: 12px;
          color: #14ad95;
        }
      }
    }
  }
}
