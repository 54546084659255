@import '../../../styles/common.scss';
@import '../../../styles/variable.scss';

// 훈련 실시 모달
.start-exam-modal {
  .ant-modal-content {
    border-radius: 0 !important;

    .ant-modal-header {
      display: flex;
      justify-content: center;
      background: #fbfbfb !important;
      border-radius: 0 !important;
      border-bottom: 1px solid #e6e6e6;

      .modal-title-area {
        padding-top: 25px;
        background-color: #fbfbfb;
        text-align: center;

        .modal-main-title {
          color: #000;
          font-size: 26px;
          font-weight: 700;
          line-height: 26px;
        }

        .modal-sub-title {
          margin-top: 10px;
          font-size: 16px;
          color: #727272;

          .point {
            color: #e45938;
            font-weight: 700;
          }
        }

        .step-area {
          padding-top: 20px;
        }

        .exam-complete {
          .step-area {
            position: relative;
            top: -20px;
            padding: 0;
          }

          .modal-title {
            color: #000000;
            font-size: 25px;
            padding: 0 15px 15px;

            .complete {
              position: relative;
              margin-left: 5px;

              .text {
                position: relative;
                z-index: 1;
              }

              .highlighter {
                position: absolute;
                left: 0;
                top: 9px;
                width: 100%;
                height: 20px;
                background-color: #f9d143;
              }
            }
          }

          .modal-text {
            padding-top: 30px;
            font-size: 15px;
            color: #333333;
          }
        }
      }
    }

    .modal-content {
      width: 504px;
      margin: 0 auto;
      line-height: 1.4;
      font-size: 13px;

      .form-wrap {

        .ant-select-multiple,
        &.multiple {
          height: 100%;
          font-size: 13px;

          &.warning {
            .ant-select-selector {
              border: 1px solid #ff1744 !important;
            }

            .ant-select-selection-placeholder {
              color: #ff1744;
            }
          }

          &.ant-select-disabled {
            .color-tag {
              opacity: 0.6;
            }
          }

          .ant-select-selection-search {
            margin-inline-start: 0;
          }

          .ant-select-selector {
            height: 100% !important;
            margin: 0;
            padding: 5px 35px 5px 7px !important;
            text-indent: 0;

            .ant-tag {
              display: flex;
              align-items: center;
            }

            .ant-tag-close-icon {
              display: none;
            }

            .anticon {
              margin-left: 5px;
              font-size: 12px;
              opacity: 0.7;

              &:hover {
                opacity: 1;
              }
            }
          }

          .ant-select-arrow {
            top: 50%;
          }

          .ant-select-dropdown {
            .ant-select-item-option {
              padding: 10px 25px;

              svg {
                display: none;
              }
            }

            .rc-virtual-list-holder-inner {
              cursor: default;
            }
          }
        }
      }

      .start-exam-border-box {
        border: 1px solid #ddd;
        border-radius: 5px;

        .header-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 18px;
          background-color: #fbfbfb;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          border-bottom: 1px solid #dedede;

          .title {
            display: flex;
            align-items: center;

            img {
              margin-left: 5px;
            }
          }
        }
      }

      .setting-box {
        padding: 10px 15px 10px 30px;

        .setting-row {
          display: flex;
          align-items: center;

          &:last-child {
            align-items: flex-start;

            .input-title {
              margin-top: 10px;
            }
          }

          .input-title {
            min-width: 110px;
          }

          .send-value {
            flex: 1;
            margin-left: 15px;
          }
        }

        .flex {
          justify-content: space-between;
        }

        .slider-status {
          width: 44px;
          height: 23px;
          line-height: 23px;
          color: #fff;
          font-size: 11px;
          font-weight: 700;
          text-align: center;
          background-color: #c5c5c5;
          border-radius: 12px;
        }
      }

      .step1-content,
      .step2-content,
      .step3-content,
      .step4-content {
        min-height: 327px;

        .anticon-close-circle {
          margin-right: 0;
        }
      }

      // STEP 1
      .step1-content {
        text-align: center;

        .prepare-summary {
          display: inline-block;
          min-width: 400px;
          margin-bottom: 14px;
          padding: 5px 10px 5px;
          color: #0f7d67;
          border: 1px solid #14ae96;
          border-radius: 17px;
          box-shadow: 3px 4px #ececec;

          &.failed {
            border-color: #d09344;
            color: #d9872f;
          }
        }

        .prepare-content {
          .prepare-row {
            display: flex;
            align-items: center;
            padding: 11px 15px 11px 12px;
            border-bottom: 1px solid #e6e6e6;

            .prepare-check {
              margin-right: 10px;
            }

            .prepare-text {
              display: flex;
              flex: 1;
              align-items: center;
              line-height: 1.3;
              color: #353535;

              .main-text {
                font-size: 16px;
              }

              .sub-text {
                margin-left: 5px;
              }
            }

            .prepare-extra {
              display: flex;

              img {
                cursor: pointer;

                &:last-child {
                  margin-left: 8px;
                }
              }
            }
          }
        }
      }

      // STEP 2
      .step2-content {
        .content-item {
          margin-top: 14px;

          &:first-child {
            margin: 0;
          }

          .extra-area {
            justify-content: right;
          }

          .random-switch {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 20px;
            font-weight: 700;
            border: 1px solid #dedede;
            border-radius: 5px;
          }

          .send-value {

            .period-area,
            .slider-area {
              @include slider(#ffc64c, #f89b0a);
              padding: 0 23px;
            }

            .period-area {
              display: flex;
              align-items: center;

              .period-text {
                width: 50px;
                text-align: center;
                color: #414141;
                font-size: 17px;
              }

              img {
                cursor: pointer;
              }
            }
          }

          .send-period-text {
            margin-top: 8px;
            color: #14ae96;
            font-weight: 700;
          }
        }
      }

      // STEP 3
      .step3-content {
        .start-exam-check {
          margin: 10px 0 20px;
          color: #717171;
          font-size: 13px;
        }

        .no-tag-area {
          margin-top: 5px;
          padding: 10px 15px;
          border-radius: 5px;
          color: #ff1744;
          border: 1px solid #ff1744;

          .no-tag-main-text {
            font-weight: 700;

            .anticon-exclamation-circle {
              margin-right: 5px;
            }
          }

          .no-tag-sub-text {
            margin-top: 5px;
            font-size: 12px;
          }
        }

        .exam-tag {
          padding: 10px 25px;

          .tag-select-option {
            color: #000;

            .tag-circle {
              width: 17px;
              height: 17px;
              margin-right: 12px;
              border-radius: 9px;
            }

            .tag-name {
              flex: 1;
              word-break: break-all;
            }

            .tag-count {
              width: 50px;
              text-align: right;
            }
          }
        }

        .setting-box {
          padding: 14px 25px 24px 30px;

          .slider-area {
            @include slider(#ffa893, #ff7e60);
            display: flex;
            align-items: center;

            .ant-slider {
              flex: 1;
              margin-right: 20px;
            }
          }
        }

        .start-exam-border-box {
          margin-top: 25px;
        }
      }

      // STEP 4
      .step4-content {
        .input-title {
          display: flex;
          justify-content: space-between;

          .template-type-icon-top {
            display: flex;
            align-items: center;
            color: #9b9b9b;
            font-size: 11px;

            .icon-area {
              margin-right: 10px;

              img {
                margin-right: 5px;
              }
            }
          }
        }

        .color-tag {
          color: #fff;
          background-color: #c5c5c5;

          img {
            margin-right: 5px;
          }
        }

        .start-exam-template {
          padding: 11px 28px !important;
          border-top: 1px solid #fafafa;
        }

        .template-select-option {
          line-height: 1em;

          .exam-type-circle {
            margin-right: 15px;
          }

          .template-info-area {
            .template-info-title {
              font-weight: 700;
            }

            .template-exam-info {
              margin-top: 5px;
              font-size: 12px;
              color: rgba(0, 0, 0, 0.6);

              .ant-tag {
                height: 19px;
                line-height: 17px;
                margin-right: 5px;
                padding: 0 5px;
                background: transparent;
                border-radius: 2px;
                font-size: 11px;
                font-weight: 700;
              }
            }

            .template-attach-info {
              margin-top: 5px;
              color: #333;
              font-size: 12px;

              .file-icon {
                margin: 0 5px;
                width: 16px;
                height: 16px;
              }
            }
          }
        }

        .start-exam-border-box {
          margin-top: 15px;

          .setting-box {
            padding: 10px 20px;

            .screen-lock-date {
              flex: 2;
            }

            .screen-lock-time {
              flex: 1;
              margin-left: 10px;
            }
          }
        }

        .screen-lock-info-text {
          margin-top: 10px;
          color: #4c7f77;
          font-size: 12px;
        }
      }

      // STEP 5
      .step5-content {
        width: 484px;
        min-height: 284px;
        margin: 0 auto;

        .result-scroll {
          height: 230px;
          overflow: auto;

          .result-row {
            display: flex;
            align-items: flex-start;
            padding: 8px;

            .result-title {
              width: 102px;
              margin: 0 10px;
              font-size: 16px;
              font-weight: 700;
            }

            .yellow-check-icon {
              position: relative;
              top: 2px;
            }

            .result-text {
              font-size: 15px;

              .result-license-wrap {
                .result-license-title {
                  font-size: 13px;
                  font-weight: 700;
                }

                .result-license-content {
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  padding-left: 5px;

                  div {
                    margin: 5px 5px 0 0;
                  }

                  .license-number {
                    min-width: 40px;
                  }

                  .point-text {
                    color: #e33c21;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }

        .result-move-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 397px;
          height: 34px;
          margin: 20px auto 0;
          color: #6e6e6e;
          font-size: 14px;
          font-weight: 700;
          border: 1px solid $brand;
          border-radius: 17px;
          box-shadow: 3px 4px #ececec;
          cursor: pointer;
        }
      }

      .license-error {
        margin-top: 15px;

        .license-error-content {
          position: relative;
          color: #c14e38;
          font-size: 15px;

          .license-error-text {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              margin-right: 5px;
            }
          }

          .license-error-popup {
            position: absolute;
            right: -310px;
            bottom: -25px;
            width: 300px;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 1px 1px 5px #909090;
            z-index: 10;

            .popup-header {
              padding: 15px 25px;
              color: #fff;
              font-size: 15px;
              background: linear-gradient(to right, #ff5656 0%, #b13838 100%);
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }

            .popup-content {
              padding: 15px;

              .license-status-row {
                display: flex;
                font-size: 13px;

                .license-name {
                  margin-top: 6px;
                  font-weight: 700;
                }

                .license-detail {
                  display: flex;
                  justify-content: space-between;
                  padding: 5px 8px;

                  .license-number {
                    min-width: 40px;
                  }

                  .license-target {
                    // width: 85px;
                    margin-right: 15px;
                  }

                  .license-period {
                    //
                  }
                }

                .license-item {
                  min-width: 50px;
                }

                .remain-count {
                  margin-left: 15px;
                }
              }
            }
          }
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: flex-end;
      padding: 13px !important;
      text-align: right !important;
      box-shadow: 0 -3px 30px -15px #aaa;

      .footer-btn {
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #69a89b !important;
        border-color: #e6e6e6 !important;

        img {
          position: relative;
          top: 1px;
        }

        &.ok {
          color: #fff !important;
          border-color: #11a991 !important;
        }
      }
    }
  }
}

.simple-modal {
  .content-item {
    margin-top: 10px;
  }

  .input-title {
    display: flex;
    justify-content: space-between;

    .template-type-icon-top {
      display: flex;
      align-items: center;
      color: #9b9b9b;
      font-size: 11px;

      .icon-area {
        margin-right: 10px;

        img {
          margin-right: 5px;
        }
      }
    }
  }

  .color-tag {
    color: #fff;
    background-color: #c5c5c5;

    img {
      margin-right: 5px;
    }
  }

  .start-exam-template {
    padding: 11px 28px !important;
    border-top: 1px solid #fafafa;
  }

  .template-select-option {
    line-height: 1em;

    .exam-type-circle {
      margin-right: 15px;
    }

    .template-info-area {
      .template-info-title {
        font-weight: 700;
      }

      .template-exam-info {
        margin-top: 5px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);

        .ant-tag {
          height: 19px;
          line-height: 17px;
          margin-right: 5px;
          padding: 0 5px;
          background: transparent;
          border-radius: 2px;
          font-size: 11px;
          font-weight: 700;
        }
      }

      .template-attach-info {
        margin-top: 5px;
        color: #333;
        font-size: 12px;

        .file-icon {
          margin: 0 5px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .start-exam-border-box {
    margin-top: 15px;

    .setting-box {
      padding: 10px 20px;

      .screen-lock-date {
        flex: 2;
      }

      .screen-lock-time {
        flex: 1;
        margin-left: 10px;
      }
    }
  }

  .screen-lock-info-text {
    margin-top: 10px;
    color: #4c7f77;
    font-size: 12px;
  }

  .extra-area {
    justify-content: right;
  }

  .form-wrap {
    input:disabled {
      background: #f5f5f5;
      color: rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
    }
  }

  .license-error {
    margin-top: 15px;

    .license-error-content {
      position: relative;
      color: #c14e38;
      font-size: 15px;

      .license-error-text {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-right: 5px;
        }
      }

      .license-error-popup {
        position: absolute;
        right: -310px;
        bottom: -25px;
        width: 300px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #909090;
        z-index: 10;

        .popup-header {
          padding: 15px 25px;
          color: #fff;
          font-size: 15px;
          background: linear-gradient(to right, #ff5656 0%, #b13838 100%);
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }

        .popup-content {
          padding: 15px;

          .license-status-row {
            display: flex;
            font-size: 13px;

            .license-name {
              margin-top: 6px;
              font-weight: 700;
            }

            .license-detail {
              display: flex;
              justify-content: space-between;
              padding: 5px 8px;

              .license-number {
                min-width: 40px;
              }

              .license-target {
                // width: 85px;
                margin-right: 15px;
              }

              .license-period {
                //
              }
            }

            .license-item {
              min-width: 50px;
            }

            .remain-count {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}

// 훈련 실시 전 요약 모달
.result-summary-modal {
  .result-summary-header {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
  }

  .result-summary-row {
    display: flex;
    padding: 8px;

    .result-summary-title {
      // width: 102px;
      min-width: 102px;
      font-weight: 700;
    }

    .result-summary-text {
      padding-left: 15px;
    }
  }
}