.admin-main-title {
  margin-top: 40px;
  padding-left: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;

  &.table-title {
    margin-top: 0px !important;
    padding-left: 35px;
    padding-right: 20px;
    margin-bottom: 8px;
  }

  &.small {
    width: 635px;
  }

  .main-title {
    font-size: 16px;
    font-weight: 600;
    margin-right: 20px;
  }
}
