.common-loading {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.8;
  z-index: 999;

  &.active {
    display: flex;
  }

  &.bg-dark {
    color: #e6e6e6;
    background-color: #000;
  }

  .loading-content {
    position: relative;
    top: -10px;
    text-align: center;
  }

  .anticon-loading {
    font-size: 28px;
  }

  .loading-text {
    margin-top: 5px;
    font-size: 13px;
  }
}
