// 필터 dropdown

.target-dropdown-area {
  display: flex;
  align-items: center;
  width: 97px;
  height: 32px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  margin-right: 5px;
  position: relative;
  cursor: pointer;

  .dropdown-arrow-area {
    border-left: none;
  }

  .default-text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: #757575;
    padding-left: 11px;
    position: absolute;
    top: 0;
    z-index: 10;
  }
}

.target-dropdown-area.border-color {
  border: 1px solid #dddddd;
}

.target-dropdown-area-border {
  background-color: #d2eae9;
  border-color: #85cdc2;

  .dropdown-arrow-area {
    border: none !important;

    .number-box {
      width: 26px;
      height: 18px;
      background-color: #14ae9e;
      border-radius: 9px;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      color: white;
      line-height: 16px;
      text-indent: -2px;
    }
  }

  .table-filter-arrow {
    right: 10px !important;
  }

  .default-text {
    color: #128687;
  }
}

.target-dropdown-area-border.border-color {
  border: 1px solid #85cdc2;
}

//팝업 드랍다운

.filter-popup {
  .column.grid {
    padding: 16px 0;

    .column {
      min-width: 180px;
      overflow: auto;
      height: 215px;
      padding: 0 5px 0 15px;

      .header {
        font-weight: 700;
        padding-bottom: 9px;
      }

      .item {
        padding: 6px 0;
        display: flex;
        cursor: pointer;

        .check-box {
          width: 16px;
          height: 16px;
          margin-top: 2px;
          background-color: #f6f6f6;
          border: 1px solid #dedede;
          border-radius: 3px;
          position: relative;

          .box {
            width: 8px;
            height: 6px;
            position: absolute;
            left: 3px;
            top: 4px;
          }

          .outline {
            display: none !important;
          }
        }

        .box-text {
          width: 120px;
          font-size: 13px;
          margin-left: 8px;
          word-break: break-all;
        }
      }
    }
  }

  .bottom-options {
    padding: 0 15px;

    .column {
      border-top: 1px solid #d9d9d9;
      padding: 12px !important;
      display: flex !important;
      justify-content: space-between !important;
      font-size: 13px;
      color: #f24343;
      display: flex;
      align-items: center;
    }

    .multi-row {
      display: block !important;
      padding-left: 8px !important;
      padding-right: 8px !important;

      .all-check-off {
        text-indent: 4px;
      }

      .inline-block {
        margin-top: 7px;
      }
    }

    .all-check-off {
      cursor: pointer;
      margin-right: 5px;
    }

    .button {
      font-size: 12px;
      padding: 0;
      border-radius: 23px;
      min-width: 82px;
      height: 30px;
    }

    .button-brand {
      color: white;
      background-color: #11a991;
    }

    .button-border {
      background-color: transparent;
      border: 1px solid #bbbbbb;
      color: #bbbbbb;
    }
  }
}

.filter-popup:before {
  width: 526px;
  -webkit-box-shadow: -1px -1px 0 0 rgba(34, 36, 38, 0.15) !important;
  box-shadow: -1px -1px 0 0 rgba(34, 36, 38, 0.15) !important;
  top: -4px;
  left: 255px;
}

//하얀 배경 툴팁

.white-bg-tooltip {
  max-width: 370px;
  margin-top: 10px !important;
  background-color: #f8f8f8 !important;
  border: 1px solid #eaeaea;
  border-radius: 3px !important;
  opacity: 1 !important;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

  .tooltip-wrapper {
    padding: 12px 15px;

    .arrow {
      background-color: black;
      width: 10px;
      height: 10px;
      position: absolute;
      left: 48%;
      top: -5px;
      transform: rotateZ(45deg);
      background-color: #f8f8f8;
      border-left: 1px solid #eaeaea;
      border-top: 1px solid #eaeaea;
    }

    .tooltip-row {
      display: flex;
      align-items: flex-start;
      padding: 6px 0;
      font-size: 13px;

      .tooltip-title {
        display: flex;
        align-items: center;
        min-width: 100px;
        text-align: left;
        color: #333333;
        font-weight: 700;

        .title-dot {
          display: block;
          margin-right: 5px;
          width: 2px;
          height: 2px;
          background-color: #333333;
          border-radius: 2px;
        }
      }

      .tooltip-context {
        color: #5e5e5e;
        word-break: break-all;
        text-align: left;

        .context-value {
          padding-right: 3px;
          float: left;
        }
      }
    }
  }
}

.close {
  display: none;
}

.react-search {
  .target-dropdown-area {
    width: 115px;
    height: 40px;
    border-radius: 20px;
    margin-left: 5px;

    .dropdown-arrow-area {
      width: 32px;
      border-left: 1px solid #ebebeb;
      right: -1px;
    }

    .default-text {
      font-size: 13px;
      padding-left: 20px;
    }
  }
}

.ant-popover {
  &.table-filter-popover {
    .ant-popover-content {
      max-width: 1000px !important;

      .ant-row {
        .ant-col {
          width: 180px;
          height: 215px;
          padding: 0 5px 0 15px;
          overflow: auto;
          border-right: 1px solid #d4d4d5;

          &:last-child {
            border: none;
          }
          .filter-group-header {
            font-weight: 700;
            padding-bottom: 9px;
          }

          .ant-checkbox-wrapper {
            padding: 6px 0;
            display: flex;
            align-items: center;
            font-size: 13px;
            cursor: pointer;

            .ant-checkbox {
              margin-top: 3px;
            }
          }
        }

        .filter-group-footer {
          position: relative !important;
          width: 100%;
          border-top: 1px solid #d9d9d9;
          display: flex !important;
          justify-content: space-between !important;
          font-size: 13px;
          color: #f24343;
          display: flex;
          align-items: center;
          padding: 12px 12px 0 12px !important;
          margin-top: 12px;

          .all-check-off {
            cursor: pointer;
            margin-right: 5px;
          }
          .inline-block {
            button {
              font-size: 12px;
              padding: 0;
              border-radius: 23px;
              min-width: 82px !important;
              height: 30px;
            }

            .button-brand {
              color: white;
              background-color: #11a991;
              border: 1px solid #11a991;
            }

            .button-border {
              background-color: transparent;
              border: 1px solid #bbbbbb;
              color: #bbbbbb;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}