.exam-template-checkbox {
  .exam-check {
    width: 14px;
    height: 14px;

    .ant-checkbox-inner {
      width: 14px;
      height: 14px;
      background-color: #dbdbdb;
    }
  }
  // .exam-check {
  //   // width: 25px;
  //   width: auto;
  //   margin-left: 5px;

  //   .ant-checkbox-inner {
  //     width: 14px;
  //     height: 14px;
  //     background-color: #dbdbdb;
  //     border: none;
  //   }

  //   .ant-checkbox-checked,
  //   .ant-checkbox-indeterminate {
  //     .ant-checkbox-inner {
  //       background-color: #a7a7a7;

  //       &::after {
  //         top: 0;
  //         left: 0;
  //         width: 100%;
  //         height: 100%;
  //         // background: url(/img/exam/list_check_on.png) no-repeat center;
  //         border: none;
  //         border-radius: 3px;
  //         transform: none;
  //       }
  //     }
  //   }

  //   .ant-checkbox-indeterminate {
  //     .ant-checkbox-inner {
  //       &::after {
  //         // background: url(/img/exam/list_check_each.png) no-repeat center;
  //       }
  //     }
  //   }
  // }
}
