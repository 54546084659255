.phishing-page-sidebar {
  width: 250px;
  height: 100%;
  padding: 10px;
  border-right: 1px solid #2e333a;
  background-color: #21262d;
  overflow-y: auto;
  user-select: none;

  .sidebar-item {
    margin: 4px 0 20px;

    input {
      margin: 0;

      &::placeholder {
        opacity: 0.5;
      }
    }

    .sidebar-item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      color: #fbfbfb;
      font-size: 12px;
      font-weight: 700;

      .step-setting {
        display: flex;
        align-items: center;
        height: 34px;
        color: #919191;
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        .step-text {
          width: 40px;
          text-align: center;
        }

        .step-buttons {
          display: flex;
          flex-direction: column;
        }
      }

      &.image-upload {
        justify-content: left;

        img {
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }

    .ant-radio-button-wrapper {
      background-color: rgba(0, 0, 0, 0.15);
      color: #919191;
      font-size: 14px;
      height: 40px;
      border: none;

      &.ant-radio-button-wrapper-checked {
        color: #fff;
        box-shadow: none !important;

        .ant-radio-button-checked {
          background-color: #00ad7b;
          border-radius: 5px;
        }
      }
    }

    .form-wrap {
      input {
        color: #919191 !important;
        background-color: rgba(0, 0, 0, 0.15);
        border: none;

        &:focus {
          color: #000;
          background-color: #fff;
        }
      }
    }

    .step-list-btn {
      width: 100%;
      line-height: 40px;
      margin-bottom: 6px;
      padding: 0 14px;
      color: #919191;
      font-size: 14px;
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.15);
      cursor: pointer;

      &.active {
        color: #fff;
        background-color: #00ad7b;
      }
    }

    .image-file-upload-area {
      padding: 4px;
      border-radius: 3px;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.15);

      .file-input-area {
        width: 100%;
        height: 60px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed #333333;
      }

      label {
        max-width: calc(100% - 20px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #919191;
        font-size: 14px;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }

    .upload-control-box {
      margin-top: 6px;
      display: flex;
      justify-content: flex-end;

      button {
        height: 18px;
        line-height: 18px;
        margin: 0 4px;
        padding: 0 6px;
        color: #cfd0d2;
        font-size: 0.8em;
        border-radius: 3px;
        background-color: hsla(0, 0%, 100%, 0.1);
        border: none;
        cursor: pointer;
      }
    }

    .image-list {
      max-height: 30%;
      margin-top: 10px;
      overflow-y: auto;
      overflow-x: hidden;

      .image-list-item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        &.active {
          input {
            color: #fff;
          }
        }

        .item-btn {
          position: relative;
          width: 200px;
          line-height: 40px;
          margin-right: 5px;
          padding: 0 14px;
          color: #919191;
          font-size: 14px;
          border: none;
          border-radius: 3px;
          background-color: rgba(0, 0, 0, 0.15);

          .image-name {
            width: 170px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
          }

          .image-source {
            width: 100%;
            height: inherit;
            background-color: transparent;
            border: none;
            cursor: text;
          }
        }

        .delete-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          height: 22px;
          background-color: transparent;
          border: none;
          border-radius: 3px;

          .anticon {
            margin-right: 0;
          }

          &:hover {
            background-color: #ffffff1a;
          }
        }
      }
    }

    &.phishing-level {
      min-height: 217px;

      .sidebar-item-title {
        margin-bottom: 3px;
      }
    }
  }
}
