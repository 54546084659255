.template-modal-content {
  display: flex;
  .modal-swich-label {
    margin-left: 15px;
  }
}

.config-row-option-text-test {
  padding: 5px 0;
  background: transparent;
  font-size: 13px;
  font-weight: 300;
  margin-top: 15px;
}