// 실행예시 모달
.attach-result-modal {
  .ant-modal-body {
    max-height: 600px;
    overflow-y: auto;
  }

  .modal-content img {
    width: 100%;
  }

  .ant-modal-footer {
    display: none;
  }
}
