.leak-count-wrap {
  display: flex;
  align-items: center;
  float: right;
  min-width: 50px;
  margin-left: 15px;

  &:first-child {
    margin-left: 0;
  }

  img {
    margin-right: 5px;
  }

  .leak-text {
    height: 12px;
    line-height: 12px;
    font-size: 11px;
    text-align: center;

    &.size {
      color: #9e9e9e;
    }
  }
}
