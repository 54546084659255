.common-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  .content-header-text {
    padding-left: 15px;

    .content-header-title {
      line-height: 30px;
      font-size: 21px;
      font-weight: 700;
      color: #000;
    }

    .content-header-sub-title {
      margin-top: 3px;
      color: #393939;
      font-size: 0.8vw;
    }
  }
  .content-header-btn-area {
    display: flex;
    align-items: center;

    .refresh-icon {
      width: 29px;
      height: 29px;
      line-height: 23px;
      text-align: center;
      border: 1px solid #d8d8d8;
      border-radius: 15px;
      cursor: pointer;
    }

    .content-header-btn {
      button {
        font-weight: 700;
      }

      a {
        font-weight: 700;
        color: inherit;
      }

      .round-grey-button {
        min-height: 30px;
        line-height: 100%;
        padding: 0 5px;
        text-align: center;
      }
    }

    .content-header-nav-btn {
      display: flex;
      align-items: center;
      height: 45px;
      margin-left: 15px;
      border: 2px solid #d2d2d2;
      border-radius: 23px;

      .nav-btn {
        display: inline-block;
        width: 100px;
        height: 44px;
        line-height: 44px;
        padding: 0 7px;
        color: #393939;
        font-size: 13px;
        text-align: center;
        border-radius: 23px;
        transition: none;

        &.active {
          color: #fff;
          font-weight: 700;
          background-color: #14ae9e;
          box-shadow: 1px 1px 5px #a5a5a5;
        }

        &:first-child {
          margin-left: -1px;
        }

        &:last-child {
          margin-right: -1px;
        }
      }
    }
  }
}
