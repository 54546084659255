.intro-page {
  position: relative;
  width: 100%;
  height: 100%;

  .body {
    min-height: 100%;
    margin: 0 auto;

    .intro-section {
      &.section-1 {
        min-height: 510px;
        padding: 10px 0;
        // background: url(/img/intro/main_top_img.png) no-repeat;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .top-center {
          max-width: 700px !important;
          line-height: 1.5;
          // margin: 120px auto 0;
          text-align: center;

          .title {
            width: 705px;
            margin: 30px auto 10px;
            color: #ddd;
            font-size: 26px;
            font-weight: 700;
          }

          .sub-title {
            width: 705px;
            color: #afafaf;
            font-size: 18px;
          }

          .login-btn {
            line-height: 55px;
            margin: 60px auto 0;
            color: #fff;
            font-size: 18px;
            font-weight: 400;
            cursor: pointer;
          }
        }
      }

      &.section-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 60px 0;

        img {
          margin-right: 100px;
        }

        .section-title {
          margin: 20px 0;
        }

        ul {
          margin-top: 15px;
          padding-left: 22px;

          li {
            line-height: 25px;
          }
        }
      }

      &.grey {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 200px;
        text-align: center;
        background-color: #ededed;
        border: 1px solid #dddddd;

        > div {
          padding: 50px 0;
          flex: 1;
        }

        .left {
          height: 100%;
          border-right: 1px solid #dddddd;
        }
      }

      &.section-4 {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 900px;
        margin: 0 auto;
        padding: 70px 0;

        .section-item {
          padding: 0 15px;
          text-align: center;

          .image-area {
            height: 105px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .section-title {
            margin-top: 20px;
          }
        }
      }

      &.section-6 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0;

        .section-item {
          font-size: 16px;

          .section-title {
            font-size: 18px;
          }
        }

        .link {
          margin-left: 10px;
          color: #00ad7b;
          font-weight: 600;
          cursor: pointer;
        }

        .start-btn {
          line-height: 100%;
          margin-left: 60px;
          padding: 13px 30px;
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          background-color: #00ad7b;
          border-radius: 20px;
          cursor: pointer;
        }
      }

      .section-title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 15px;
      }

      .main-text {
        font-size: 16px;
        padding-left: 5px;
      }

      div {
        line-height: 25px;
      }
    }
  }
}
