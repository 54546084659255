$brand: #00ad7b;
$brand-blue: #8884d8;
$brand-green: #14ae96;
$subBrand-dark: #3e313c;
$subBrand: #4d3948;
$subBrand-light: #81737f;
$text-color: #afafaf;
$saxa-text-color: #333333;
$saxa-brand-color: #00a0e9;

$black: #000000;
$charcoal: #333333;
$red: #db2828;
$dark-red: #a83d29;
$blue: #4183c4;
$brown: #a5673f;
$orange: #f69322;
$yellow: #ffb400;
$white: #ffffff;
$cure: #2185d0;

$target: #1ba8d1;
$send: #ffcf0e;
$read: #f69c2e;
$down: #711bd1;
$infection: #db2828;
//$cure: #3ebda6;

$default-line: #e5e5e5;
$summary-line: #e8e8e8;

// tag 색상
$tag-red: #ff0000;
$tag-orange: #ff8a00;
$tag-yellow: #ffcc00;
$tag-olive: #c1d91f;
$tag-lightgreen: #76ea05;
$tag-green: #12bb52;
$tag-pink: #d90cec;
$tag-purple: #9c00ff;
$tag-violet: #632fda;
$tag-blue: #125be1;
$tag-lightblue: #0ea7e7;
$tag-emerald: #02dcc5;
$tag-lightpink: #ff6ce2;
$tag-plum: #ff1a86;
$tag-darkplum: #c90d6a;
$tag-brown: #a44200;
$tag-ash: #bb9b7c;
$tag-apricot: #ffb99a;
$tag-grey: #767676;
$tag-black: #1b1c1d;

// 템플릿 타입 색상
$temp-system: #2bb2de;
$temp-user: #1a3acd;
$temp-warning: #853cb4;
$temp-file: #e45858;
$temp-research: #6eb43f;
$temp-phishing: #f4ab38;
$temp-phishing-direct: #f8449e;

// 첨부파일 관리 색상
$attach-exe: #00758e;
$attach-exe-bk: #e2f5f9;
$attach-lnk: #0048ff;
$attach-lnk-bk: #e5ecff;
$attach-doc: #1892df;
$attach-doc-bk: #e6f5ff;
$attach-html: #00ccff;
$attach-html-bk: #f2fcff;
$attach-function: #004a80;
$attach-restrict: #c20037;

// + material grey code
$grey50: #fafafa;
$white-grey: #f6f6f6;
$grey80: #f8f8f8;
$grey100: #f5f5f5;
$grey150: #f2f2f2;
$grey200: #eeeeee;
$grey250: #e6e6e6;
$light-grey: #ededed;
$grey300: #e0e0e0;
$grey350: #d6d6d6;
$grey: #dddddd;
$grey400: #bdbdbd;
$grey450: #b1b1b1;
$dark-grey: #afafaf;
$grey500: #9e9e9e;
$double-grey: #888888;
$grey600: #757575;
$dim-grey: #6e6e6e;
$grey650: #726e6b;
$grey700: #616161;
$grey800: #424242;
$grey900: #212121;

// 삭제
$tag-afafaf: #afafaf;
$tag-5db482: #5db482;
$tag-ff6961: #ff6961;
$tag-e49135: #e49135;
$tag-4a6785: #4a6785;
$tag-303591: #303591;
$tag-4c4c4c: #4c4c4c;
$tag-815b3a: #815b3a;
$tag-62a9ff: #62a9ff;
$tag-333333: #333333;
$tag-6e6e6e: #6e6e6e;
$tag-49182c: #49182c;

// 교육 (진행, 완료)
$green-72bc45: #72bc45;
$red-c72525: #c72525;