.phishing-page-header {
  display: flex;
  height: 60px;
  line-height: 59px;

  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;

    img {
      width: 118px;
    }
  }

  .center {
    display: flex;
    align-items: center;
    font-size: 12px;

    .header-btn {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 12px 0 8px;
      border-bottom: 1px solid transparent;
      cursor: pointer;

      &:hover {
        border-bottom: 1px solid #00ad7b;
      }

      .anticon-caret-right {
        font-size: 14px;
      }
    }

    .reserve-area {
      display: flex;
      align-items: center;
      height: 30px;
      margin-left: 10px;
      cursor: default;

      .reserve-label {
        height: 20px;
        line-height: 20px;
        margin-right: 10px;
        padding: 0 8px;
        background-color: #00ad7b;
        border-radius: 5px;
      }
    }
  }
}

.reserve-popover {
  font-size: 12px;

  .ant-popover-title {
    padding: 10px 15px;

    .reserve-header {
      display: flex;

      div {
        width: 170px;
        font-weight: 700;
      }
    }
  }

  .ant-popover-inner-content {
    padding: 0 !important;

    .reserve-data {
      display: flex;
      padding: 0 15px;
      border-bottom: 1px solid #f0f0f0;

      div {
        width: 170px;
        padding: 4px 0;
        letter-spacing: 0.3px;
      }
    }

    .reserve-example {
      padding: 20px 15px 10px;
    }
  }
}
