.phishing-page-editor {
  width: calc(100% - 250px);
  height: 100%;
  display: flex;
  flex: 1;

  .type-text {
    margin: 0;
    padding: 14px 16px 6px;
    color: #cfd0d2;
    font-size: 12px;
    background-color: #21262d;
    letter-spacing: 0;
  }

  .vertical-resizable {
    border-right: 1px solid #2e333a;
    min-width: 300px;
    max-width: calc(100% - 300px);
    max-height: 100%;

    .left-side {
      height: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .react-codemirror2 {
    flex: 1 1;
    height: calc(100% - 35px);
    border-left: none;

    .CodeMirror {
      height: 100%;
      background-color: #21262d;

      .CodeMirror-gutter {
        background-color: #21262d;
      }

      .CodeMirror-sizer {
        margin-left: 43px !important;

        .CodeMirror-linenumber {
          color: #919191;
          background-color: #21262d;
        }
      }

      .CodeMirror-line {
        span {
          letter-spacing: 0 !important;
        }
      }

      .CodeMirror-hscrollbar,
      .CodeMirror-vscrollbar {
        outline: none;
      }

      .CodeMirror-scrollbar-filler {
        background-color: transparent;
      }
    }
  }

  .code-area {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;

    &.html {
      height: 100%;
      overflow: hidden;
    }

    &.js {
      border-top: 1px solid #2e333a;
      min-height: 80px;
    }

    &.css {
      height: 100%;
      border-bottom: 1px solid #2e333a;
    }
  }

  .right-side {
    min-width: 300px;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #21262d;

    .right-side-resizable {
      min-height: 80px;
      max-height: calc(100% - 80px);
    }

    .phishing-iframe {
      flex: 1 1;
      width: 100%;
      // height: 100%;
      border: none;

      &.on {
        display: block;
        background-color: #fff;
      }
    }
  }
}
