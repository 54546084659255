.list-step-wrap {
  display: flex;
  align-items: center;
  height: inherit;
  padding: 0 !important;

  .step-line-wrap {
    position: relative;
    padding: 0 !important;
  }

  .step-icon-wrap {
    position: relative;
    height: inherit;
    padding: 0 !important;

    .step-badge {
      position: absolute;
      top: -4px;
      right: -4px;
      width: 15px;
      height: 15px;
      line-height: 15px;
      color: #fff;
      font-size: 11px;
      font-weight: bold;
      text-align: center;
      background-color: #72bc45;
      border-radius: 50%;
      z-index: 3;
    }

    .circle-icon-wrap {
      width: 31px;

      img {
        position: absolute;
        left: 0;
      }
    }

    .step-wrap {
      display: flex;
      align-items: center;
      height: inherit;
    }

    .step-count-text {
      min-width: 30px;
      margin-left: 5px;
    }
  }
}
