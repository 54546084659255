.form-button-group {
  width: 100%;

  .ant-radio-group {
    width: 100%;
    display: flex;
    margin-top: 5px;

    .ant-radio-button-wrapper {
      position: relative;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      margin-left: 10px;
      padding: 0;
      color: #000;
      background-color: #fff;
      border: 1px solid #dedede;
      border-radius: 5px;
      transition: none;

      &:first-child {
        margin-left: 0;
      }

      &::before {
        display: none;
      }

      span {
        &:last-child {
          position: relative;
          display: inline-block;
          padding: 0 18px;

          .button-check-icon {
            display: none;
          }
        }
      }
    }

    .ant-radio-button-wrapper-checked {
      color: #14ae96;
      font-weight: 700 !important;
      background-color: #f0f8f6;
      border-color: #14ae96 !important;
      box-shadow: 0 0 0 1px #14ae96 !important;

      // .button-check-icon {
      //   position: absolute;
      //   display: inline-block !important;
      //   width: 18px;
      //   height: 100%;
      //   background: url(/img/exam/template/exam_selected.png) no-repeat center;
      //   background-size: 12px 7px;
      // }
    }

    &.warning {
      border: 1px solid #ff1744;
      border-radius: 5px;
    }
  }
}
