.admin-basic-conf {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: #ffffff;
  padding: 20px 20px 5px;
  overflow-y: auto;

  &.table {
    height: 100%;
    margin-bottom: 0;
    overflow: hidden;
  }

  .menu-button {
    margin: 0 5px;
  }
}

// react table
.text-align-center {
  text-align: center !important;

  img {
    margin: 0 auto;
  }
}

.text-align-right {
  text-align: right;
}

.overflow-visible {
  overflow: visible !important;
}

.exam-leak-file-area,
.exam-leak-pc-area {
  height: calc(100% - 113px);

  .react-search {
    padding: 20px 5px 11px;
  }
}

.react-table-area .react-table .rt-table .react-table-td {
  color: #424242;
  font-size: 13px;

  &.number-text {
    font-size: 12px;
    color: #b1b1b1;
  }

  &.sub-text {
    color: #b1b1b1;
  }

  &.last-text {
    padding-right: 20px;
  }
}

.react-table-area {
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  height: calc(100% - 58px);
  min-height: 400px;

  .react-table {
    flex: 1;
    position: relative;
    background-color: #ffffff;
    font-size: 13px;
    height: calc(100% - 251px);

    .ReactTable {
      border: 1px solid rgba(0, 0, 0, 0.1);
      height: 100%;

      .react-table-tbody-wrapper {
        // height: 100%;
        height: calc(100% - 54px);
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .rt-tbody {
          // margin-bottom: 20px;
          height: 100%;

          // 테이블 설정 메뉴
          .table-settings {
            .anticon {
              font-size: 14px;
              margin-right: 12px;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .table-no-data {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .rt-resizer {
        display: inline-block;
        position: absolute;
        width: 36px;
        top: 0;
        bottom: 0;
        right: -18px;
        touch-action: none;
        cursor: col-resize;
        z-index: 10;
        pointer-events: auto;
      }
    }

    .rt-table {
      height: 100%;
      border-radius: 3px;
      overflow-y: hidden;
      min-height: 100px;

      .-header {
        background-color: #fcfcfc;
        border-bottom: 1px solid #e3e3e3;
        box-shadow: none;

        .rt-tr {
          height: 53px;
        }

        .react-table-th {
          padding: 12px 0;
          border-right: 0;
          color: #393939;
          font-weight: bold;
          box-shadow: none !important;

          &:not(.no-sort):hover {
            background-color: #f1f1f1;
            cursor: pointer;
          }

          & > div:first-child {
            height: 30px;
            display: flex;
            border-right: 1px solid #f0f0f0;
            justify-content: center;
            align-items: center;
            color: #393939;
            font-weight: bold;
          }

          &:last-child > div:first-child {
            border-right: 0;
          }

          .img-desc-ordering {
            position: absolute;
            left: 8px;
            top: calc(50% - 4px);
            // width: 16px;
            // height: 12px;
            // background: url(/img/table/ico_descending.png) no-repeat;
          }

          .img-asc-ordering {
            position: absolute;
            left: 8px;
            top: calc(50% - 4px);
            // width: 16px;
            // height: 12px;
            // background: url(/img/table/ico_ascending.png) no-repeat;
          }

          .header-text-area {
            display: flex;
            justify-content: center;
            align-items: center;

            &.filter {
              padding-left: 12px;
            }
          }

          .header-desc,
          .header-asc {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            background-color: transparent;
            width: 100%;
            height: 100%;
          }

          &.-sort-desc .header-desc,
          &.-sort-asc .header-asc {
            display: block;
          }

          a {
            color: #393939 !important;
            z-index: 111;
          }

          &.isFiltered > div {
            color: #14ae96 !important;
          }

          .anticon {
            margin-right: 0;
          }
        }
      }

      .-filters {
        border-bottom: 0;

        .react-table-th {
          padding: 0px;
        }
      }

      .react-table-td {
        padding: 14px 10px;
        border: none;
      }

      .rt-tr-group {
        min-height: 50px;
        border-bottom: 0;

        &:nth-child(2) {
          background-color: #f9f9f9;
        }

        &:hover .react-table-td {
          background-color: #f1f1f1 !important;
        }

        .-even .react-table-td {
          background-color: #f9f9f9;
        }

        .delete-item {
          text-decoration: line-through !important;
          color: #f4d3d4 !important;

          .react-table-td:not(.settings) {
            color: #f4d3d4 !important;
          }
        }

        .license-applying-item {
          color: #ffb400 !important;
          font-weight: bold;

          .react-table-td:not(.settings) {
            color: #ffb400 !important;
            font-weight: bold;
          }
        }

        .license-status-orange {
          color: #f2711c !important;
          font-weight: bold;

          .react-table-td:not(.settings) {
            color: #f2711c !important;
            font-weight: bold;
          }
        }
      }
    }

    // 데이터가 없을때
    .noData-area {
      position: absolute;
      // top: 40%;
      // left: 42%;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 18px;
      text-align: center;

      .image {
        margin: auto;
        margin-bottom: 10px;
      }
    }
  }
}
