@import '../../../styles/variable.scss';

// Label
.config-class-title-wrap {
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 700;
}

.input-gap {
  margin-bottom: 20px;
}

.config-template {
  padding-bottom: 40px;

  .config-class {
    padding-bottom: 15px;
    padding: 15px 45px 0;
    max-width: 685px;
    min-width: 350px;

    .config-class-top {
      text-align: left;
      padding-bottom: 10px;

      .config-class-title {
        font-size: 20px;
        font-weight: 500;
        background: transparent;
        padding-left: 0;
        color: $charcoal;

        &.none {
          display: none;
        }
      }
    }
  }

  .config-class-box {
    background-color: $white;
    width: 100%;
  }
}

.ant-popover {
  .ant-popover-content {
    max-width: 300px;
    pre {
      display: block;
      padding: 9.5px;
      line-height: 1.4;
      word-break: break-all;
      word-wrap: break-word;
      background-color: #f5f5f5;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .accessIpQmark-content {
      margin: 0;
      font-size: 12px;
      color: #888;
    }
  }
}

.config-row-option {
  .config-row-option-title {
    position: relative;
    text-align: left;
    align-self: center;
    margin-right: auto;

    .config-row-option-text {
      padding: 5px 0;
      background: transparent;
      font-size: 13px;
    }

    .accessIpQmark {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 7px;
      margin-left: 4px;
    }
  }

  .config-row-option-value {
    &.right {
      text-align: right;
    }

    .ant-input {
      width: 100%;
      border: 1px solid #dfdfdf !important;
      text-indent: 0px;
      box-shadow: none !important;
      padding: 10px;
      margin-top: 5px;
      margin-bottom: 10px;
      border-radius: 5px;
    }
  }

  // 대리점
  &.resultMailSendSellMngr {
    display: flex;
    align-items: center;

    .config-row-option-title {
      margin-right: 0;
    }

    .config-row-option-value {
      flex: 1;
    }

    .manager-info-edit {
      display: inline-flex;
      align-items: center;
      margin-left: 20px;

      .detail-title {
        margin-right: 5px;
      }

      .detail-value {
        width: 100% !important;

        form {
          position: relative;
          display: flex;

          .form-field-wrap {
            .form-wrap {
              input {
                height: 26px;
                margin: 0;
                padding: 3px 7px;
                text-indent: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
            .arrow-box {
              top: 35px;
              bottom: inherit;
            }
          }
        }
      }

      .edit-btns {
        display: flex;

        .edit-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 26px;
          height: 26px;
          background-color: #f6f6f6;
          border: 1px solid #ddd;
          cursor: pointer;

          &:last-child {
            border-left: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          .anticon {
            margin-right: 0;
          }
        }
      }
    }
  }
}

form {
  position: relative;

  .config-input-switch {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

// 저장
.config-row-option-result {
  margin-top: 9px;
  font-size: 12px;
  color: #14ae96;

  // textarea {

  // }
}
