.footer-area {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 20px 0;

  .version {
    position: absolute;
    bottom: 15px;
    right: 30px;
    color: #888;

    &.saxa {
      color: #333;
    }
  }

  .footer-corp {
    text-align: center;
  }

  .footer-contents {
    width: 1000px;
    margin: 0 auto;

    .footer-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .footer-item {
      display: flex;
      flex: 1;
      font-size: 12px;

      &:first-child {
        flex: 1.8;
      }

      .left {
        width: 75px;
        text-align: center;

        .logo {
          width: 47px;
        }

        img {
          width: 100%;
        }
      }

      .right {
        display: flex;

        .title {
          margin-bottom: 15px;
          font-weight: 700;
          font-size: 16px;
        }

        .sub-title {
          font-size: 13px;
          font-weight: 700;

          a {
            margin-right: 10px;
          }
        }

        .sub-item {
          padding: 0 25px;
        }
      }
    }
  }
}
