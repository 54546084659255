$brand-green: #14ae96;

.admin-license-config {
  width: 595px;
  padding-left: 45px;
  padding-bottom: 30px;

  .group {
    margin-top: 40px;

    &:first-child {
      margin-top: 30px;
    }

    .group-title {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
    }

    .group-item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .item-title {
        font-size: 13px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 10px;
      }
    }
  }

  .message {
    font-size: 13px;
    margin-top: 5px;
    font-weight: bold;

    &.red {
      color: #ff0000;
    }

    &.green {
      color: #12bb52;
    }
  }

  .set-file {
    margin-top: 10px;
    display: flex;

    form {
      position: relative;
      width: calc(100% - 80px);
      border-radius: 5px;
      overflow: hidden;
      background-color: #ffffff;

      .file-input {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed #3f3f3f;
        border-radius: 5px;

        &.active,
        &:hover {
          background-color: #dddddd;
        }

        label {
          width: calc(100% - 20px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #919191;
          font-size: 13px;
          margin-bottom: 0;
          text-align: center;
        }

        input {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
          position: absolute;
        }
      }
    }

    .file-btns {
      display: flex;
      align-items: center;

      .upload {
        height: 80px;
      }
    }
  }

  .set-text {
    margin-top: 5px;
    display: flex;

    .input-text {
      width: calc(100% - 80px);
      height: 40px;
      padding: 3px 10px;
      border-radius: 5px;
      border: 1px solid #dddddd;
    }
  }

  .default-button {
    width: 80px;
    height: 40px;
    font-size: 13px;
    color: #ffffff;
    background-color: #5e5e5e;
    margin-left: 5px;
    border: none;
    border-radius: 5px;
    outline: none;

    &:hover {
      box-shadow: 1px 1px 3px #616161;
    }

    &.disable {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
