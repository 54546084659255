.attach-info-popover {

  .attach-popover-item {
    display: flex;
    align-items: center;
    margin: 5px 8px;

    .attach-popover-title {
      width: 80px;
    }

    .temp-type-label {
      height: 20px;
      line-height: 18px;
      font-size: 11px;
    }

    .attach-label {
      height: 20px;
      line-height: 18px;
      margin-right: 3px;
      padding: 0 8px;
      font-size: 11px;
      border: 1px solid;
      border-radius: 3px;
    }

    .attach-file-icon {
      width: 20px;
      height: 20px;
    }
  }
}
