.license-add-edit-modal {
  .modal-border-box {
    margin-bottom: 20px;
  }

  .adjust-btn {
    padding: 3px 8px;
    color: #2185d0;
    font-size: 11px;
    font-weight: 700;
    background-color: #fff;
    border: 1px solid #2185d0;
    border-radius: 3px;
    outline: none;
  }

  .license-row {
    display: flex;
    margin-top: 20px;

    > div {
      flex: 1;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
