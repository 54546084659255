.mypage {
  .support-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 215px;
    margin: 30px auto 0;
    padding: 20px;
    border-radius: 5px;
    background-color: #e2e2e2;

    .support-title {
      margin-bottom: 5px;
      font-size: 13px;
      font-weight: 700;
    }

    img {
      margin-right: 5px;
    }

    a {
      color: inherit !important;
    }
  }
}
