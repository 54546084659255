.react-pagenation-area {
  position: relative;
  display: flex;
  justify-content: center;
  height: 80px;
  padding: 17px 0;

  .react-pagenation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin: 0;
    padding-left: 0;
    padding-inline-start: 0px;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    box-shadow: 2px 2px 5px #f3f3f3;
    font-size: 13px;
    font-weight: bold;

    .pagenation {
      display: flex;

      .pagenation-bt {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        cursor: pointer;
        width: 30px;
        height: 27px;
        border-radius: 14px;
        outline: none;

        &.underline {
          text-decoration: underline;
        }

        &:hover {
          background-color: #e8e8e8;
        }
      }
    }

    .previous,
    .next {
      &.disabled {
        opacity: 0.2;

        a:hover {
          background-color: transparent;
          cursor: auto;
        }
      }

      .pagenation-bt {
        width: 42px;
        height: 38px;

        .image-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          width: inherit;
          height: 30px;
        }
      }
    }

    .previous {
      margin-right: 3px;

      .pagenation-bt {
        border-radius: 0;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;

        .image-wrap {
          border-right: 1px solid #dcdcdc;
        }
      }
    }

    .next {
      margin-left: 3px;

      .pagenation-bt {
        border-radius: 0;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;

        .image-wrap {
          border-left: 1px solid #dcdcdc;
        }
      }
    }

    .each-area {
      &.active .each-area-link {
        background-color: #f0f0f0;
      }

      .each-area-link {
        width: 36px;
      }
    }
  }
}
