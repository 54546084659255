input {
  outline: none;
}

// text input title
.input-title {
  display: inline-block;
  font-size: 13px;
  color: #838383;
}

.text-field-title {
  margin-top: 16px;
  font-size: 13px;
  color: #838383;
  display: inline-block;
  vertical-align: top;
}

// 로그인, 회원가입 text input
.underline-input {
  width: 100%;
  min-height: 30px;
  padding: 5px 0;
  margin: 0 auto 5px;
  color: #fff;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #b1b1b1;

  &::placeholder {
    color: #afafaf;
  }
}

.tag-input {
  height: 100% !important;
  margin: 0 !important;
  text-indent: 0 !important;
  background-color: transparent;
  border: none !important;
  border-bottom: 1px solid !important;
  border-radius: 0 !important;
}

.textarea-input {
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  outline: none;
  resize: none;

  &.warning {
    border-color: #ff1744;
  }
}
