.mypage-license-area {
  margin: 0 0 30px 0;
  overflow-y: auto;
  width: 100%;

  button {
    min-width: 125px;
    height: 45px;
    margin: 0 5px;
    color: white;
    font-weight: 700;
    font-size: 12px;
    background-color: #11a991;
    border: 1px solid #11a991;
    border-radius: 23px;
    &:hover {
      box-shadow: 1px 1px 5px #616161;
    }
  }
  .license-grid-box {
    min-width: 600px;

    .license-user-summary-box {
      margin-bottom: 30px;
      border: none !important;
      box-shadow: none !important;

      .license-user-result {
        display: flex;
        min-height: 104px;
        padding: 14px 30px;
        line-height: 20px;
        align-items: center;
        background: #f8f8f9;
        border: 1px solid #d4d4d5;

        .license-result-content {
          margin-left: 17px;
          flex: 1 1 auto;
          vertical-align: middle;

          .license-result-header {
            margin-bottom: 3px;
            font-weight: 700;
            font-size: 17px;
          }
        }
      }

      .license-user-detail {
        border: 1px solid #d4d4d5;
        border-top-color: transparent;
        padding: 1em;

        .target-domain-list {
          display: flex;
          line-height: 1;

          .target-domain {
            width: 120px;
            border: none;
            text-align: center;
            font-weight: 700;
            padding: .5833em 0;

            .detail {
              display: block;
              font-size: 12px;
              margin: 5px 0 5px 0 !important;
              color: #747474;
            }
          }

          .target-domain-license {
            margin-left: 10px;
            border: none;
            font-weight: 700;
            padding: .5833em .2em;

            .license-state {
              display: flex;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.license-status-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .license-status-title {
    display: flex;
    width: 90px;
    align-items: center;
    margin-right: 10px;

    & > span {
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
      }
    }

    &.license-request {
      color: #ffb400;
    }

    &.license-use {
      color: #2986c7;
      // color: #11a991;
    }

    &.license-ready {
      color: #2185d0;
    }

    &.license-expired {
      color: #c4282d;
    }

    img {
      display: inline-block;
    }
  }

  .license-number {
    display: inline-block;
    width: 40px;
    margin-right: 10px;
  }

  .license-date {
    display: inline-block;
    width: 140px;
    text-align: right;
  }

  .license-count {
    display: inline-block;
    min-width: 88px;
    text-align: right;
    margin-right: 25px;
  }

  .license-count-last {
    width: 140px;
    margin-right: 0 !important;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }
}
