.video-js {
  width: 100%;
  margin-top: 5px;
  border-radius: 5px;

  .vjs-poster {
    background-size: contain !important;
  }

  .vjs-playback-rate-value {
    line-height: 2.5;
    font-size: 12px;
  }

  .vjs-subs-caps-button {
    display: none;
  }

  .vjs-control-bar {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
