.react-search {
  display: flex;
  justify-content: flex-start;
  padding: 0 5px 11px 13px;

  .tooltip {
    position: relative;
    left: 0;
  }

  .seacrch-input-area {
    position: relative;
    display: flex;
    border: 1px solid #ebebeb;
    border-radius: 20px;

    // 검색 타입

    .search-dropdown-area {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .search-dropdown {
        z-index: 1;
        cursor: pointer;
        width: 150px;
        height: 38px;
        padding: 9px 25px 9px 19px;
        color: #515151;
        font-size: 13px;
        display: flex;
        align-items: center;

        .text {
          width: 100px;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
          overflow: hidden;
        }
      }

      .dropdown-arrow-area {
        border-left: 0;
        border-right: 1px solid #ebebeb;
      }
    }

    .react-search-input {
      //width: 226px;
      height: 38px;
      font-size: 14px;
      color: #000000;
      border: none;

      input {
        width: 226px;
        height: 100%;
        border: 0px;
        border-radius: 20px;
        padding-left: 15px;
        padding-right: 60px;
        &::placeholder {
          color: #dfdfdf;
        }
      }

      // 검색 되었을때
      // background-color: #f6f6f6;
    }

    .bt-search-input {
      position: absolute;
      justify-content: center;
      right: 0;
      min-width: 60px;
      width: 60px;
      height: 38px;
      border-radius: 19px;
      border: none;
      line-height: 0;
    }
  }

  .bt-filter-init {
    width: 38px;
    height: 38px;
    margin-left: 4px;
    margin-top: 1px;
    border: none;
    line-height: 0;
    padding: 0;
    border-radius: 20px;

    &:hover {
      background-color: #f6f6f6;
    }
  }

  .pageSize-area {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: auto;

    .pageSize-status {
      margin-right: 15px;
      font-size: 13px;
      color: #4a4a4a;
    }

    .pageSize-dropdown {
      width: 100px;
      height: 40px;
      padding: 9px 30px 9px 18px;
      color: #393939;
      font-size: 13px;
      border-radius: 20px;
      border: 1px solid #ebebeb;
      background-color: transparent !important;
      z-index: 1;
      cursor: pointer;
    }

    .dropdown-arrow-area {
      right: 4px;
      width: 28px;
      border-left: 1px solid #ebebeb;
    }
  }

  .dropdown {
    .menu {
      padding: 3px 4px;

      .item {
        margin: 4px 0;
        padding: 4px 16px !important;
        border-radius: 5px;
        height: 22px;

        &:hover {
          // font-weight: bold;
          color: #14ae96;
          background-color: #f1f1f1;
        }

        &.selected {
          font-weight: bold;
          color: #14ae96;
        }
      }
    }

    &.text-center .menu .item .text {
      display: flex;
      justify-content: center;
      align-self: center;
    }
  }
}
