@import './button.scss';
@import './input.scss';
@import './color.scss';
@import './tooltip.scss';
@import './base.scss';

// font
@font-face {
  font-family: 'MEIRYO';
  src: url('/styles/fonts/MEIRYO.woff') format('woff'),
    url('/styles/fonts/MEIRYO.ttf') format('truetype');
  font-weight: normal;
  font-display: fallback;
  font-style: normal;
}

// style
.content {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
  overflow: auto;

  &.full {
    height: 100%;
    overflow: hidden;
  }
}

.error-message {
  color: #db2828;
  font-size: 13px;
  text-align: left;
}

.bold {
  font-weight: 700;
}

.flex {
  display: flex;
  align-items: center;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.cursor-default {
  cursor: default;

  &:hover {
    color: inherit !important;
    background-color: transparent !important;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none !important;
  }
}

.anticon {
  margin-right: 5px;
}

label {
  font-weight: normal !important;
}

// 메인 레이아웃 (left, center, right) - 훈련결과, 교육결과, 신고결과, 대상관리, 템플릿관리
.common-content-layout {
  display: flex;
  width: 100%;
  height: 100%;

  .common-left-side {
    width: 250px;
    border-right: 1px solid #e8e8e8;
    background-color: #f8f8f8;
  }

  .common-content {
    display: flex;
    width: calc(100% - 250px);

    .common-inner {
      position: relative;
      width: calc(100% - 454px);
      min-width: 940px;
      padding: 35px 30px;

      .list-item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .list-column {
          display: flex;
          padding: 0 10px 0 5px;
          color: #333;

          &.center {
            justify-content: center;
          }

          &.flex-end {
            justify-content: flex-end;
          }
        }

        .name-column {
          flex: 1;
          min-width: 50px;
          padding: 0 5px;
          color: #000;
        }

        .email-column {
          min-width: 150px;
          color: #000;

          .target-name {
            display: inline;
            width: 200px;
          }
        }

        .period-column {
          flex: 1;
          min-width: 150px;

          .exam-period {
            font-size: 12px;
          }
        }

        .status-column {
          display: flex;
          flex: 2;
          justify-content: flex-end;
          height: 31px;
          line-height: 31px;
          padding: 0 15px;
        }

        .exam-extra-column {
          display: flex;

          .empty {
            width: 80px;
          }
        }

        .status-text {
          display: flex;
          justify-content: flex-end;
          min-width: 90px;
          padding-right: 10px;
          font-size: 13px;
          font-weight: 700;
          color: #14ae96;

          .anticon {
            font-size: 12px;
            margin-right: 2px;
          }
        }

        .round-border {
          display: inline-block;
          width: 90px;
          margin-left: 5px;
          padding: 5px 8px;
          font-size: 13px;
          text-align: center;
          border: 1px solid #a5a6a9;
          border-radius: 19px;
        }
      }
    }

    .common-inner-right {
      position: relative;
      width: 454px;
      padding: 20px 20px 50px 15px;
      border-left: 2px solid #e8e8e8;
      overflow-y: auto;

      .detail-area {
        .detail-item {
          padding: 20px 25px 45px 15px;

          .detail-title {
            font-size: 17px;
            font-weight: 700;
          }

          .detail-contents {
            position: relative;
            margin: 26px 0 0 13px;

            .detail-row {
              display: flex;
              align-items: flex-start;
              width: 100%;
              min-height: 32px;
              padding: 2px 0;
              font-size: 13px;
            }

            .detail-text {
              width: 135px;
            }

            .detail-value {
              display: flex;
              align-items: center;
              width: calc(100% - 135px);
              padding: 0 7px;
            }
          }
        }

        .add-view-btn {
          display: inline-block;
          margin-top: 10px;
          padding: 2px 8px 2px;
          font-size: 13px;
          border: 1px solid #ddd;
          border-radius: 3px;
          cursor: pointer;

          .anticon-plus-circle {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

// antd switch
.ant-switch {
  transition: none;

  &.ant-switch-checked {
    background-color: #14ae96;

    &:focus {
      box-shadow: none;
    }
  }
}

// antd slider
@mixin slider($trackColor, $handleColor) {
  .ant-slider {
    &:hover {
      .ant-slider-rail {
        background-color: #e9e9e9;
      }

      .ant-slider-track {
        background-color: $trackColor;
      }

      .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: $handleColor;
      }
    }

    .ant-slider-rail {
      height: 8px;
      background-color: #e9e9e9;
      border-radius: 6px;
    }

    .ant-slider-track {
      height: 8px;
      background-color: $trackColor;
      transition: none;
    }

    .ant-slider-handle {
      top: 6px;
      width: 15px;
      height: 15px;
      border: 4px solid $handleColor;
      transition: none;
      cursor: grab;
    }

    .ant-slider-handle-dragging {
      border-color: $handleColor !important;
    }
  }
}

// ant tag
.ant-tag {
  text-wrap: inherit;

  &.color-tag {
    line-height: 19px;
    margin: 3px 4px 2px 0;
    padding: 5px 12px;
    font-size: 12px;
    border-radius: 16px;
    background-color: #fff;

    .ant-tag-close-icon {
      margin-left: 5px;
      color: unset;
    }

    &::after {
      display: none;
    }
  }

  &:hover {
    opacity: 1;
    cursor: default;
  }

  &.small {
    padding: 3px 10px;
  }
}

// ant checkbox
.ant-checkbox {
  &.ant-checkbox-checked {
    &::after {
      border-color: rgba(34, 36, 38, 0.25);
      border-radius: 3px;
      animation: none;
      transition: none;
    }
  }

  .ant-checkbox-inner {
    border-color: #d4d4d5 !important;
    border-radius: 3px;
    background-color: transparent;
    transition: none;

    &::after {
      border-color: #000;
      transition: none;
    }
  }
}

// scrollbar
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.15);
}

// 우측 알림 팝업(스낵바)
.ant-notification {
  top: 100px !important;
  right: 40px !important;

  .ant-notification-notice {
    border-top: 2px solid $brand;
  }

  .ant-notification-notice-icon {
    color: $brand;
  }

  .ant-notification-notice-message {
    color: #353535;
  }
}

// ant radio
.ant-radio-wrapper {
  display: flex;
  align-items: center;

  .ant-radio-inner {
    transition: none;

    &::after {
      transition: none;
    }
  }

  .ant-radio-checked,
  .ant-radio:hover,
  .ant-radio-input:focus {
    .ant-radio-inner {
      border-color: #d9d9d9;
      box-shadow: none;

      &::after {
        background-color: #14ae96;
        transition: none;
      }
    }

    &::after {
      border: 1px solid #d9d9d9;
      animation: none;
    }
  }
}

// 프로그레스바
.ant-progress {
  line-height: 1.4;

  &.disabled {
    opacity: 0.45;
    pointer-events: none;
  }

  .ant-progress-inner {
    background-color: #e6e6e6;

    .ant-progress-bg {
      min-width: 2em;
      transition: none;
    }
  }

  .ant-progress-outer {
    margin: 0;
    padding: 0;
  }

  .ant-progress-text {
    position: absolute;
    width: attr(data-width);
  }
}