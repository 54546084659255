.sync-test-btn {
  min-width: 100px;
  line-height: 100%;
  padding: 7px 10px;
  background-color: #11a991;
  color: #fff;
  font-size: 13px;
  border: 0;
  border-radius: 5px;
  outline: none;
}
