@import '../../../styles/variable.scss';

.execute-result-content {
  width: 484px;
  min-height: 284px;
  margin: 0 auto;

  .result-scroll {
    height: 230px;
    overflow: auto;

    .result-row {
      display: flex;
      align-items: flex-start;
      padding: 8px;

      .result-title {
        width: 120px;
        margin: 0 10px;
        font-size: 16px;
        font-weight: 700;
      }

      .yellow-check-icon {
        position: relative;
        top: 2px;
      }

      .result-text {
        font-size: 15px;

        .result-license-wrap {
          .result-license-title {
            font-size: 13px;
            font-weight: 700;
          }

          .result-license-content {
            display: flex;
            align-items: center;
            font-size: 14px;
            padding: 5px;

            .license-number {
              min-width: 40px;
            }

            .point-text {
              color: #e33c21;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .result-move-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 397px;
    height: 34px;
    margin: 20px auto 0;
    color: #6e6e6e;
    font-size: 14px;
    font-weight: 700;
    border: 1px solid $brand;
    border-radius: 17px;
    box-shadow: 3px 4px #ececec;
    cursor: pointer;
  }
}