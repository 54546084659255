@import '../../styles/variable.scss';

.admin-login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrap-input {
    margin: 7px 0;
    display: flex;
    flex-direction: column;
  }

  // 로그인
  .admin-login-area {
    padding-bottom: 70px;

    .admin-login-title {
      background-color: transparent;
      font-size: 18px;
      padding-left: 0;
      font-weight: bold;
    }
    .login-input {
      margin-bottom: 35px;

      .error-message {
        margin-left: 20px;
        font-weight: bold;
      }

      .admin-login-btn {
        font-size: 16px;
        font-weight: bold;
        background-color: #3cb482;
        color: #fff;
        width: 100%;
        height: 40px;
        border-radius: 5px;
        border: 0;
        margin-top: 35px;
      }
    }
    input {
      width: 300px !important;
      height: 36px !important;
      border: 1px solid #dfdfdf;
      text-indent: 10px;

      margin: 5px 0;
      border-radius: 5px;
      &::placeholder {
        color: #dfdfdf;
      }
    }
  }

  // 첫로그인
  .admin-first-edit-area {
    &.change-passwd {
      width: 350px;

      .admin-title {
        color: #333333 !important;
      }
    }

    .display-flex {
      align-items: center;
      display: flex;

      .admin-title {
        background-color: transparent;
        font-size: 18px;
        padding: 0;
        color: $grey;
        font-weight: 700;
      }

      img {
        display: flex;
        width: 42px;
        height: 42px;
        margin-right: 10px;
      }
    }

    .text-field-title {
      margin-top: 16px;
      font-size: 13px;
      color: #838383;
      display: inline-block;
      vertical-align: top;
    }

    .first-wrap-input {
      display: flex;
    }

    .modify-confirm {
      padding: 50px 0 30px;
      text-align: center;

      .confirm-message {
        color: $brand;
        font-size: 18px;
        font-weight: bold;
      }
    }

    .button-area {
      display: flex;
      justify-content: center;
      padding: 10px;
      margin-top: 10px;

      .bt-change,
      .bt-noChange {
        width: 120px;
        display: inline-block;
        background-color: transparent;
        border: 1px solid $brand;
        border-radius: 5px;
        padding: 5px 10px;
        text-align: center;
        color: $brand;
        font-size: 14px;
        font-weight: bold;
        height: 30px;
        margin: 0 2px;
      }

      .bt-noChange {
        border: 1px solid $dark-grey;
        color: $dark-grey;
      }
    }
  }
}
