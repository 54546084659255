.faq-page {
  position: relative;
  width: 100%;
  height: 100%;

  .faq-body {
    min-height: 100%;
    margin: 0 auto;

    .faq-header {
      max-width: 1000px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      padding: 40px 0;
      font-size: 28px;
      font-weight: 700;

      .search-area {
        position: relative;
        display: flex;
        margin-right: 50px;

        .search-input {
          width: 300px;
          padding: 10px 35px 10px 5px;
          font-size: 18px;
          border: none;
          border-bottom: 2px solid #333;
          outline: none;
        }

        .search-icon {
          position: absolute;
          right: 10px;
          cursor: pointer;
        }
      }
    }

    .faq-current {
      margin: 0 auto;
      padding: 15px 0;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;

      .current-box {
        max-width: 1000px;
        margin: 0 auto;

        .current-label {
          display: inline-block;
          padding: 5px 15px;
          color: #4183c4;
          font-size: 15px;
          background-color: #f5f5f5;
          border-radius: 4px;
        }

        .anticon-right {
          margin-left: 5px;
          color: #b4b4b4;
        }
      }
    }

    .faq-content {
      display: flex;
      max-width: 1000px;
      margin: 0 auto;

      .faq-sidebar {
        width: 210px;
        padding: 20px 0;

        .menu-item {
          padding: 10px 15px;
          color: #6e6e6e;
          font-size: 16px;
          cursor: pointer;

          &.active {
            font-weight: 700;
            border-right: 2px solid #1b1c1d;
          }
        }
      }

      .faq-content-detail {
        width: calc(100% - 210px);
        padding: 20px 50px 60px;
        border-left: 1px solid #dddddd;

        .detail-title {
          padding: 10px 0 30px;
          font-size: 28px;
          font-weight: 700;
          border-bottom: 2px solid #00ad7b;
        }

        .detail-menu {
          padding: 10px 0;
          border-bottom: 1px solid #dddddd;
          cursor: pointer;

          &:last-child {
            border-bottom: 1px solid #00ad7b;
          }

          &.open {
            .detail-question {
              font-size: 24px;
              font-weight: 700;

              .arrow-img {
                transform: rotate(180deg);
              }
            }

            .detail-answer {
              display: block;
            }
          }

          &.no-result {
            padding: 30px 0;
          }
        }

        .detail-question {
          display: flex;
          align-items: center;
          font-size: 18px;
          padding: 10px 0;

          .index {
            width: 40px;
            color: #8884d8;
            font-size: 20px;
          }

          .faq-row {
            width: calc(100% - 40px);
            display: flex;
            align-items: center;
            justify-content: space-between;

            &::before,
            &::after {
              display: none;
            }
          }
        }

        .detail-answer {
          display: none;
          height: 100%;
          line-height: 24px;
          padding: 10px 20px 20px 40px;
          font-size: 16px;
          font-weight: normal;
          cursor: default;

          &:hover {
            color: inherit;
          }
        }
      }
    }
  }
}
