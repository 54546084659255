@import '../../styles/variable.scss';

.mudmanage {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  .mudmanage-tab {
    height: 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 225px;
    border-right: 0.5px solid rgba(0, 0, 0, 0.2);
    overflow-y: auto;

    .version-box {
      padding: 15px 20px 10px;

      .version-logo {
        display: flex;
        align-items: center;
      }

      .mf-logo {
        width: 96px;
      }
    }

    .mudmanage-menu {
      margin: 5px 0;

      .mudmanage-menu-item {
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
          color: $black !important;
        }

        &.ant-menu-item {
          margin-top: 2px;
          margin-bottom: 2px;
        }

        &.ant-menu-item-selected {
          color: $brand !important;
          font-weight: 700 !important;
          background-color: rgba(0, 0, 0, 0.05);
        }

        &.submenu {
          color: rgba(0, 0, 0, 0.5);
          padding-left: 34px !important;
          font-size: 12px !important;
          height: 20px;
          line-height: 20px;
          background-color: white;
        }

        &.no-click-menu {
          background-color: white;
          color: $black !important;
        }

        .mudmanage-tab-icon {
          float: right;
          margin-top: 13px;
        }
      }
    }
  }

  .mudmanage-main {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}